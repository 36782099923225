import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Chanoeka({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Chanoeka | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Chanoeka, het Lichtfeest, en zijn tradities en vieringen." />
  <meta name="keywords" content="Chanoeka, Lichtfeest, Joodse feestdag, menorah, tradities, Jeruzalem" />
  <meta property="og:title" content="Chanoeka" />
  <meta property="og:description" content="Leer meer over de betekenis en viering van Chanoeka, inclusief tradities en symboliek." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/chanoeka" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="chanoeka-info">
        <h1>Wat is Chanoeka?</h1>
        <p>
          Chanoeka, ook wel bekend als het Lichtfeest, is een acht dagen durend Joods feest dat de herinwijding van de Tweede Tempel in Jeruzalem viert. 
          Het feest begint op de 25e dag van de Joodse maand Kislev, wat meestal eind november of december valt.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Tradities en Vieringen</h3>
        <p>
          Tijdens Chanoeka steken Joodse gezinnen elke avond een kandelaar aan, bekend als de menorah, waarbij ze elke avond een nieuwe kaars toevoegen. 
          Het draaien van de dreidel (een vierzijdige tol) en het eten van gefrituurde lekkernijen zoals latkes (aardappelpannenkoeken) en sufganiyot (jam-donuts) zijn ook populaire tradities.
        </p>
        <h3>Symboliek van Chanoeka</h3>
        <p>
          Het feest symboliseert de overwinning van het licht op de duisternis en de herinwijding van de heilige tempel na de overwinning op de Seleucidische Grieken. 
          Chanoeka is een tijd van vreugde en samenkomst, waarbij men de wonderen en tradities herdenkt.
        </p>
        <h3>Internationale Vieringen</h3>
        <p>
          Chanoeka wordt wereldwijd door Joodse gemeenschappen gevierd, waarbij de menorah vaak op openbare plaatsen wordt aangestoken, 
          om het licht en de boodschap van hoop en veerkracht te delen.
        </p>
        <h3>Data en Viering</h3>
        <p>
          De datum van Chanoeka varieert elk jaar volgens de Joodse maankalender, maar het valt meestal in de maand december. 
          De viering duurt acht dagen, wat het verhaal symboliseert van de olie in de tempel die wonderbaarlijk acht dagen bleef branden.
        </p>
        <p>
          Chanoeka is een speciale tijd voor Joodse gezinnen om samen te komen, te bidden, te eten en de rijke traditie van hun erfgoed te vieren.
        </p>
      </div>
    </div>
  );
}

export default Chanoeka;