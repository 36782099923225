import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagVanDeYoga({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van de Yoga | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over de Internationale Dag van de Yoga, inclusief de voordelen en manieren om deze dag te vieren." />
  <meta name="keywords" content="Internationale Dag van de Yoga, yoga, welzijn, gezondheid, meditatie" />
  <meta property="og:title" content="Internationale Dag van de Yoga" />
  <meta property="og:description" content="Leer meer over de betekenis en viering van de Internationale Dag van de Yoga." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20de%20yoga" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="dag-yoga-info">
        <h1>Internationale Dag van de Yoga</h1>
        <p>
          De Internationale Dag van de Yoga, gevierd op 21 juni, is een speciale dag om de voordelen van yoga voor zowel lichaam als geest wereldwijd te promoten. Yoga is een eeuwenoude praktijk 
          die mensen helpt om balans, flexibiliteit en innerlijke rust te vinden in hun leven.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Oorsprong van de Internationale Dag van de Yoga</h3>
        <p>
          De Verenigde Naties hebben de Internationale Dag van de Yoga in 2014 officieel erkend, en het wordt sindsdien wereldwijd gevierd. De datum 21 juni werd gekozen omdat het de langste dag 
          van het jaar is op het noordelijk halfrond, wat symbolisch wordt geassocieerd met licht, bewustwording en groei.
        </p>
        <h3>Voordelen van Yoga voor Lichaam en Geest</h3>
        <p>
          Yoga heeft talloze voordelen voor zowel lichaam als geest, zoals het verbeteren van flexibiliteit, kracht, ademhaling en focus. Het helpt ook bij het verminderen van stress, angst en 
          andere mentale spanningen, waardoor het een effectieve manier is om welzijn te bevorderen.
        </p>
        <h3>Hoe Vier Je de Internationale Dag van de Yoga?</h3>
        <p>
          Mensen vieren deze dag door deel te nemen aan yogasessies, meditatie-evenementen, en andere activiteiten die zijn gericht op lichamelijke gezondheid en mentale rust. Over de hele wereld 
          worden speciale workshops en evenementen georganiseerd om yoga toegankelijk te maken voor iedereen.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagVanDeYoga;