import React from 'react';
import './AboutUs.css';  // Create a CSS file for styling
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function AboutUs() {
  return (
    <div className="about-us-container">
      <Helmet>
  <title>Over Ons | Vrije Dagen NL</title>
  <meta name="description" content="Leer meer over Vrije Dagen NL, uw bron voor informatie over nationale feestdagen, schoolvakanties en belangrijke datums in Nederland." />
  <meta name="keywords" content="over ons, vrije dagen, feestdagen, schoolvakanties, Nederland" />
  <meta property="og:title" content="Over Ons | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek meer over Vrije Dagen NL en onze missie om u te voorzien van informatie over feestdagen en vakanties." />
  <meta property="og:url" content="https://vrije-dagen.nl/about-us" />
  <meta property="og:type" content="website" />
</Helmet>
      <h1>Over Ons</h1>
      <p>Welkom bij Vrije Dagen NL! De site die u voorziet van informatie over nationale feestdagen, schoolvakanties, en andere belangrijke datums in Nederland.</p>

      <h2>Onze Missie</h2>
      <p>Onze missie is om mensen te helpen hun vrije dagen en vakanties beter te plannen door duidelijke en up-to-date informatie te bieden over officiële feestdagen, schoolvakanties, en evenementen in Nederland.</p>

      <h2>Waarom Ons Kiezen?</h2>
      <p>Met Vrije Dagen NL weet u altijd wanneer de volgende vrije dag of vakantie is, en kunt u makkelijk plannen maken voor uw familie en vrienden.</p>

      <h2>Contact</h2>
      <p>Neem contact met ons op via <a href="mailto:webmaster@vrijedagen.nl">info@vrijedagen.nl</a> voor vragen of suggesties.</p>
    </div>
  );
}

export default AboutUs;
