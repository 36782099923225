import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './BlogTables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/blogPosts.json`)
      .then(response => setPosts(response.data.posts))
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  return (
    <div className="blog-container">
      <Helmet>
  <title>Blog | Vrije Dagen NL</title>
  <meta name="description" content="Lees onze blogposts over vakanties, speciale dagen en meer. Ontdek nuttige informatie en tips voor je vrije dagen in Nederland." />
  <meta name="keywords" content="blog, vakantiedagen, speciale dagen, Nederland, vrije dagen, vakantie tips" />
  <meta property="og:title" content="Blog | Vrije Dagen NL" />
  <meta property="og:description" content="Bezoek onze blog voor informatie over vakantiedagen, feestdagen en tips om je vrije tijd optimaal te benutten." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/blog" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <h1>Blog</h1>
      <ul className="blog-list">
        {posts.map(post => (
          <li key={post.id} className="blog-list-item">
            <Link to={`/blog/${post.id}`}>
              <img 
                src={`${process.env.PUBLIC_URL}${post.image}`} 
                alt={post.title} 
                className="blog-image" 
                onError={(e) => { e.target.src = `${process.env.PUBLIC_URL}/images/default.jpg`; }}
              />
              <h2>{post.title}</h2>
            </Link>
            <p>{post.summary}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Blog;
