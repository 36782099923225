import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Sinterklaasavond({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Sinterklaasavond | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Sinterklaasavond, de Nederlandse traditie van cadeautjes en surprises op 5 december." />
  <meta name="keywords" content="Sinterklaasavond, Sinterklaas, cadeautjes, surprises, Nederlandse traditie, 5 december" />
  <meta property="og:title" content="Sinterklaasavond" />
  <meta property="og:description" content="Lees meer over de viering van Sinterklaasavond, met cadeautjes, gedichten en verrassingen in Nederland." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/sinterklaasavond" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="sinterklaasavond-info">
        <h1>Wat is Sinterklaasavond?</h1>
        <p>
          Sinterklaasavond, gevierd op 5 december, is een belangrijke Nederlandse traditie waarbij kinderen cadeautjes ontvangen van Sinterklaas. 
          Het feest gaat gepaard met gedichten, surprises, en gezellige familiebijeenkomsten.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Sinterklaasavond</h3>
        <p>
          De traditie van Sinterklaasavond gaat terug tot de middeleeuwen en is gebaseerd op de legende van Sint Nicolaas, de beschermheilige van kinderen. 
          In de loop der jaren is het uitgegroeid tot een feest vol gezelligheid, waarbij kinderen vol verwachting uitkijken naar de komst van Sinterklaas.
        </p>
        <h3>Traditionele Viering</h3>
        <p>
          Sinterklaasavond wordt gevierd met cadeautjes, gedichten en surprises. 
          Vaak wordt een dobbelspel gespeeld waarbij cadeautjes op een speelse manier worden uitgewisseld. 
          Het feest is ook een gelegenheid om familie en vrienden bij elkaar te brengen.
        </p>
        <h3>Surprises en Gedichten</h3>
        <p>
          Een belangrijk onderdeel van de viering zijn de surprises: creatieve en vaak grappige verpakkingen waarin cadeautjes verstopt zitten. 
          Deze gaan gepaard met gedichten waarin een humoristische of plagerige boodschap verwerkt is.
        </p>
        <h3>De Rol van Zwarte Piet</h3>
        <p>
          Zwarte Piet, de helper van Sinterklaas, speelt een grote rol tijdens de viering. 
          Hij helpt Sinterklaas bij het uitdelen van cadeautjes en zorgt voor vermaak voor de kinderen. 
          In de afgelopen jaren is de rol van Zwarte Piet aangepast vanwege culturele gevoeligheden.
        </p>
        <h3>De Betekenis van Sinterklaasavond</h3>
        <p>
          Sinterklaasavond staat symbool voor geven, delen en samen zijn. 
          Het is een tijd van gezelligheid en warmte, waarbij familie en vrienden centraal staan. 
          De traditie blijft belangrijk in de Nederlandse cultuur, vooral voor kinderen.
        </p>
      </div>
    </div>
  );
}

export default Sinterklaasavond;