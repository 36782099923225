import React, { useEffect, useRef } from 'react';

const AdSenseAd = ({ client, slot, format = 'auto', responsive = 'true', layoutKey = '' }) => {
  const adRef = useRef(null);

  useEffect(() => {
    // Load the ad only if it's not already loaded
    if (window.adsbygoogle && adRef.current) {
      try {
        window.adsbygoogle.push({});
      } catch (e) {
        console.error("Error loading AdSense ads", e);
      }
    }
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: 'display:block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-1007884522567805"
        data-ad-slot="8045914234"
        /* data-adtest={adTest ? 'on' : 'off'} */
        data-adtest="on">
      </ins>
    </div>
  );
};

export default AdSenseAd;


