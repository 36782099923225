import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet';


function Herfstvakantie({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
       <Helmet>
        <title>Herfstvakantie in Nederland | Vrije Dagen NL</title>
        <meta name="description" content="Ontdek alles over de Herfstvakantie in Nederland en plan je vrije dagen." />
        <meta name="keywords" content="herfstvakantie, vakantiedagen, schoolvakantie, Nederland, vrije dagen" />
        <meta property="og:title" content="Herfstvakantie in Nederland" />
        <meta property="og:description" content="Plan je herfstvakantie en ontdek leuke activiteiten in Nederland." />
        <meta property="og:url" content="https://www.vrije-dagen.nl/herfstvakantie" />
        <meta property="og:type" content="website" />
      </Helmet>
        <h1>Wat is de Herfstvakantie?</h1>
        <p>
          De Herfstvakantie is een schoolvakantie in Nederland die meestal plaatsvindt in oktober. Deze vakantie biedt leerlingen en leraren een pauze in het midden van het eerste schoolsemester 
          en valt samen met het begin van de herfst.
        </p>
      <div className="herfstvakantie-info">
        <AdSenseAd />
        <h3>Schoolvakantie voor Basisonderwijs en Voortgezet Onderwijs</h3>
        <p>
          De Herfstvakantie geldt voor zowel het basisonderwijs als het voortgezet onderwijs. Het is een kans voor studenten om even bij te komen, op te laden en zich voor te bereiden op de rest van het semester. 
          De exacte data kunnen per regio verschillen.
        </p>
        <h3>Alle herfstvakantie data op een rij:</h3>
      <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SchoolHolidayTable holidays={filteredSchoolHolidays} />
      <AdSenseAd />
        <h3>Activiteiten tijdens de Herfstvakantie</h3>
                <p>
          Veel gezinnen gebruiken de Herfstvakantie om er even tussenuit te gaan, zowel in Nederland als naar bestemmingen in het buitenland. Populaire activiteiten zijn boswandelingen, 
          fietstochten en het bezoeken van herfstmarkten. De kleurrijke herfstbladeren maken deze periode bijzonder aantrekkelijk voor buitenactiviteiten.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/herfstvakantie-family.jpg`} alt="herfstvakantie familie" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />
        <h3>Herfstfestivals en Evenementen</h3>
        <p>
          Tijdens de Herfstvakantie worden er door het hele land verschillende evenementen en festivals georganiseerd. Denk aan Halloween-evenementen, herfstmarkten en speciale tentoonstellingen in musea. 
          Deze evenementen bieden vermaak en activiteiten voor het hele gezin.
        </p>
        <img src={`${process.env.PUBLIC_URL}/images/herfstvakantie-autumn-festival.jpg`} alt="herfstvakantie festival" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />
        
        <h3>Rust en Ontspanning</h3>
        <p>
          Naast actieve uitstapjes, is de Herfstvakantie ook een tijd voor rust en ontspanning. Veel mensen kiezen ervoor om thuis te blijven, te genieten van de gezellige herfstsfeer en quality time door te brengen met hun gezin.
        </p>
        <h3>Voorbereiding op de Rest van het Schooljaar</h3>
        <p>
          De Herfstvakantie biedt ook een moment om vooruit te kijken naar de rest van het schooljaar. Het is een tijd om plannen te maken, achterstanden in te halen en zich mentaal voor te bereiden op de komende maanden.
        </p>
        <p>
          Al met al biedt de Herfstvakantie een waardevolle onderbreking in het schooljaar, een gelegenheid om te reizen en te ontspannen, en een kans om nieuwe ervaringen op te doen. 
          Of je nu kiest voor verre reizen, lokale avonturen of rustige dagen thuis, de Herfstvakantie biedt voor ieder wat wils.
        </p>
      </div>
    </div>
  );
}

export default Herfstvakantie;
