import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Secretaressedag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Secretaressedag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Secretaressedag in Nederland, inclusief geschiedenis en vieringstips." />
  <meta name="keywords" content="Secretaressedag, administratieve professionals, waardering, Nederland" />
  <meta property="og:title" content="Secretaressedag in Nederland" />
  <meta property="og:description" content="Leer meer over Secretaressedag, de geschiedenis en hoe je deze dag kunt vieren in Nederland." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/secretaressedag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="secretaressedag-info">
        <h1>Wat is Secretaressedag?</h1>
        <p>
          Secretaressedag is een speciale dag die jaarlijks gevierd wordt om de inzet en bijdrage van secretaresses en administratieve professionals te waarderen. 
          Het is een gelegenheid om stil te staan bij het werk dat zij doen en hun belangrijke rol in het ondersteunen van bedrijven en organisaties te erkennen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Secretaressedag</h3>
        <p>
          Secretaressedag vindt zijn oorsprong in de Verenigde Staten in de jaren 50. De dag werd geïntroduceerd om het beroep van secretaresse te promoten en om waardering te tonen voor hun werk.
          In Nederland wordt Secretaressedag gevierd op de derde donderdag van april en heeft het sindsdien aan populariteit gewonnen.
        </p>
        <h3>Hoe Secretaressedag te Vieren</h3>
        <p>
          Op Secretaressedag verrassen werkgevers en collega's hun secretaresses vaak met bloemen, cadeaus, of een etentje. Het is een moment om hen in het zonnetje te zetten en te laten zien hoe belangrijk hun bijdrage is 
          aan het succes van de organisatie.
        </p>
        <h3>Waarom Secretaressedag Belangrijk is</h3>
        <p>
          Secretaresses en administratieve professionals spelen een cruciale rol in het soepel laten verlopen van dagelijkse bedrijfsactiviteiten. 
          Secretaressedag biedt een kans om hen te bedanken voor hun harde werk, toewijding en professionaliteit. Het is belangrijk om deze waardering niet alleen op Secretaressedag, maar ook het hele jaar door te tonen.
        </p>
        <p>
          Of het nu gaat om het organiseren van vergaderingen, beheren van agenda's of het ondersteunen van verschillende afdelingen, 
          secretaresses zijn de onzichtbare krachten die ervoor zorgen dat alles op rolletjes loopt. Laten we hen vandaag en elke dag waarderen!
        </p>
      </div>
    </div>
  );
}

export default Secretaressedag;