import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function WereldDiabetesDag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Wereld Diabetes Dag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Wereld Diabetes Dag en hoe u kunt bijdragen aan bewustwording over diabetes." />
  <meta name="keywords" content="Wereld Diabetes Dag, diabetes, gezondheid, bewustwording, preventie" />
  <meta property="og:title" content="Wereld Diabetes Dag" />
  <meta property="og:description" content="Leer meer over Wereld Diabetes Dag en de impact van diabetes op de wereldwijde gezondheid." />
  <meta property="og:url" content="https://vrije-dagen.nl/wereld%20diabetes%20dag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="wereld-diabetes-dag-info">
        <h1>Wat is Wereld Diabetes Dag?</h1>
        <p>
          Wereld Diabetes Dag, gevierd op 14 november, is een dag om bewustzijn te creëren over diabetes en de impact ervan op het leven van mensen. 
          Deze dag richt zich op het informeren van het publiek over de risicofactoren, preventie en behandeling van diabetes.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Bewustwording en Educatie</h3>
        <p>
          Wereld Diabetes Dag heeft als doel om mensen te informeren over de symptomen, complicaties en risicofactoren van diabetes. 
          Het benadrukt het belang van een gezonde levensstijl, regelmatige lichaamsbeweging en een uitgebalanceerd dieet om het risico op diabetes te verminderen.
        </p>
        <h3>Symbool van Wereld Diabetes Dag</h3>
        <p>
          Het blauwe cirkelsymbool is wereldwijd het herkenningsteken van Wereld Diabetes Dag. Het staat symbool voor eenheid in de strijd tegen diabetes 
          en de gezamenlijke inspanning om de ziekte te voorkomen en te beheersen.
        </p>
        <h3>Evenementen en Campagnes</h3>
        <p>
          Over de hele wereld worden op deze dag verschillende evenementen en campagnes georganiseerd om het bewustzijn te vergroten. 
          Dit kunnen lezingen, gratis gezondheidscontroles, wandeltochten en online campagnes zijn om mensen te betrekken en te informeren over diabetes.
        </p>
        <h3>De Impact van Diabetes</h3>
        <p>
          Diabetes is een chronische aandoening die miljoenen mensen wereldwijd treft. Het kan leiden tot ernstige gezondheidsproblemen zoals hart- en vaatziekten, 
          nierfalen en blindheid als het niet goed wordt beheerd. Het verhogen van het bewustzijn helpt bij vroege diagnose en betere zorg.
        </p>
        <h3>Hoe kunt u helpen?</h3>
        <p>
          U kunt een verschil maken door bewustzijn te verspreiden over diabetes in uw gemeenschap, deel te nemen aan lokale evenementen, en door uw eigen gezondheid te beheren. 
          Samen kunnen we een wereld zonder complicaties door diabetes realiseren.
        </p>
      </div>
    </div>
  );
}

export default WereldDiabetesDag;