import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleJazzdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Jazzdag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek de culturele impact van Internationale Jazzdag en vier de muzikale bijdragen van jazz wereldwijd." />
  <meta name="keywords" content="Internationale Jazzdag, jazzmuziek, cultuur, muziekfeest, 30 april, Vrije Dagen NL" />
  <meta property="og:title" content="Internationale Jazzdag" />
  <meta property="og:description" content="Leer over de geschiedenis en de betekenis van Internationale Jazzdag. Vier jazzmuziek wereldwijd op 30 april." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20jazzdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-jazzdag-info">
        <h1>Wat is Internationale Jazzdag?</h1>
        <p>
          Internationale Jazzdag, gevierd op 30 april, is een dag die wereldwijd de muzikale en culturele bijdrage van jazz viert. Jazz heeft een diepe invloed gehad op de ontwikkeling van muziek en cultuur over de hele wereld.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Betekenis van Jazz</h3>
        <p>
          Jazzmuziek is meer dan alleen een genre; het is een kunstvorm die grenzen overstijgt en culturen verbindt. Het heeft een belangrijke rol gespeeld in de evolutie van muziek, en heeft vele andere muziekgenres beïnvloed, waaronder blues, rock, en hiphop.
        </p>
        <h3>Hoe Internationale Jazzdag te Vieren</h3>
        <p>
          Op Internationale Jazzdag worden er wereldwijd concerten, workshops, en evenementen georganiseerd. Muzikanten en jazzliefhebbers komen samen om te genieten van live optredens, en delen hun passie voor deze unieke muziekstijl.
        </p>
        <h3>De Geschiedenis van Jazz</h3>
        <p>
          Jazz ontstond aan het begin van de 20e eeuw in de Verenigde Staten, met wortels in de Afrikaanse ritmes en Europese harmonieën. Het groeide uit tot een wereldwijde muziekbeweging die een platform biedt voor artistieke expressie en culturele diversiteit.
        </p>
        <h3>Jazz als Cultureel Erfgoed</h3>
        <p>
          Jazz is niet alleen een muziekstijl; het is een symbool van vrijheid, creativiteit, en culturele uitwisseling. Het wordt beschouwd als een belangrijk cultureel erfgoed dat mensen uit verschillende achtergronden samenbrengt.
        </p>
      </div>
    </div>
  );
}

export default InternationaleJazzdag;