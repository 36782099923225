import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagVanHetGeluk({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van het Geluk | Vrije Dagen NL</title>
  <meta name="description" content="Leer alles over de Internationale Dag van het Geluk en hoe we collectief kunnen werken aan een gelukkiger leven." />
  <meta name="keywords" content="Internationale Dag van het Geluk, geluk, welzijn, wereldwijde dag, VN, positiviteit" />
  <meta property="og:title" content="Internationale Dag van het Geluk" />
  <meta property="og:description" content="Ontdek het belang van geluk en welzijn op de Internationale Dag van het Geluk." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20het%20geluk" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-dag-van-het-geluk-info">
        <h1>Wat is de Internationale Dag van het Geluk?</h1>
        <p>
          De Internationale Dag van het Geluk, gevierd op 20 maart, is een wereldwijde dag die gericht is op het bevorderen van geluk en welzijn voor iedereen. 
          Het doel is om mensen bewust te maken van het belang van geluk en hoe we collectief kunnen werken aan een gelukkiger leven.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van de Internationale Dag van het Geluk</h3>
        <p>
          De Internationale Dag van het Geluk werd in 2012 ingesteld door de Verenigde Naties als een manier om het belang van geluk en welzijn wereldwijd te erkennen. 
          Het benadrukt dat geluk een universeel doel is dat voor iedereen bereikbaar moet zijn, ongeacht hun omstandigheden.
        </p>
        <h3>Het Belang van Geluk en Welzijn</h3>
        <p>
          Geluk en welzijn zijn essentiële onderdelen van een vervullend leven. Ze hebben een directe invloed op onze gezondheid, relaties, en ons vermogen om obstakels te overwinnen. 
          Op deze dag worden mensen aangemoedigd om na te denken over wat hen echt gelukkig maakt en hoe ze dit kunnen delen met anderen.
        </p>
        <h3>Hoe Je Bijdragen aan Geluk</h3>
        <p>
          Geluk is niet alleen een individueel streven, maar ook een collectief doel. Iedereen kan bijdragen aan het vergroten van het welzijn in hun gemeenschap door 
          vriendelijkheid, empathie en solidariteit te tonen. Kleine daden van vriendelijkheid kunnen een groot verschil maken in het leven van anderen.
        </p>
        <p>
          De Internationale Dag van het Geluk herinnert ons eraan dat we allemaal de kracht hebben om positiviteit en geluk te verspreiden. 
          Door stil te staan bij wat ons gelukkig maakt, kunnen we ook anderen inspireren om hetzelfde te doen.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagVanHetGeluk;