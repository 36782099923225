import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="not-found-container">
      <h1>404 - Pagina Niet Gevonden</h1>
      <p>Sorry, de pagina die je zoekt bestaat niet.</p>
      <Link to="/">Ga terug naar de homepage</Link>
    </div>
  );
}

export default NotFound;