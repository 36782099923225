import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Valentijnsdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Valentijnsdag | Vier de Liefde | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Valentijnsdag, de dag van de liefde en romantiek. Leer meer over de geschiedenis, tradities en hoe deze dag wereldwijd wordt gevierd." />
  <meta name="keywords" content="Valentijnsdag, liefde, romantiek, 14 februari, cadeaus, bloemen, Vrije Dagen NL" />
  <meta property="og:title" content="Valentijnsdag | Vier de Liefde" />
  <meta property="og:description" content="Lees over de tradities en geschiedenis van Valentijnsdag, de dag waarop liefde en romantiek wereldwijd worden gevierd." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/valentijnsdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="valentijnsdag-info">
        <h1>Wat is Valentijnsdag?</h1>
        <p>
          Valentijnsdag wordt elk jaar op 14 februari gevierd en is wereldwijd bekend als de dag van de liefde en romantiek. 
          Op deze dag laten mensen hun waardering en genegenheid voor hun geliefden blijken door middel van cadeaus, bloemen, kaarten en romantische gebaren.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Valentijnsdag</h3>
        <p>
          De oorsprong van Valentijnsdag gaat terug tot de Romeinse tijd, waar het feest zijn naam te danken heeft aan Sint Valentijn, een priester die naar verluidt verliefde koppels in het geheim trouwde. 
          Over de jaren heen heeft de dag zich ontwikkeld tot een feestdag die in veel landen ter wereld wordt gevierd.
        </p>
        <h3>Tradities rondom Valentijnsdag</h3>
        <p>
          Typische tradities op Valentijnsdag zijn het uitwisselen van wenskaarten, bloemen, vooral rozen, en cadeaus zoals chocolade. 
          Veel mensen gebruiken deze dag ook als gelegenheid om uit eten te gaan of een romantisch uitje te plannen met hun partner.
        </p>
        <h3>Hoe Valentijnsdag Wereldwijd Gevierd Wordt</h3>
        <p>
          Hoewel de manier van vieren per land verschilt, is de essentie van Valentijnsdag overal hetzelfde: liefde en romantiek. 
          In sommige landen worden er ook vriendschappen gevierd op deze dag, en sturen mensen kaarten naar familieleden en vrienden om hun genegenheid te tonen.
        </p>
        <p>
          Of je nu kiest voor een romantisch diner, een mooi cadeau of een handgeschreven liefdesbrief, Valentijnsdag draait om het vieren van de liefde in al zijn vormen. 
          Het is een dag om dankbaar te zijn voor de speciale mensen in je leven.
        </p>
      </div>
    </div>
  );
}

export default Valentijnsdag;