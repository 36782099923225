import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Wereldmilieudag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
    <title>Wereldmilieudag | Vrije Dagen NL</title>
    <meta name="description" content="Leer meer over Wereldmilieudag, de oorsprong, thema's en hoe deze dag wereldwijd wordt gevierd om milieubewustzijn te vergroten." />
    <meta name="keywords" content="Wereldmilieudag, milieuproblemen, VN, milieu, duurzaamheid, natuurbehoud" />
    <meta property="og:title" content="Wereldmilieudag | Vrije Dagen NL" />
    <meta property="og:description" content="Ontdek alles over Wereldmilieudag, van de geschiedenis tot de jaarlijkse thema's en campagnes om het milieu te beschermen." />
    <meta property="og:url" content="https://www.vrije-dagen.nl/wereldmilieudag" />
    <meta property="og:type" content="website" />
</Helmet>
      <div className="milieudag-info">
        <h1>Wat is Wereldmilieudag?</h1>
        <p>
          Wereldmilieudag wordt elk jaar gevierd op 5 juni en is bedoeld om wereldwijd bewustzijn te creëren over milieuproblemen. 
          Het is een dag waarop individuen, gemeenschappen en organisaties samenkomen om actie te ondernemen voor de bescherming van onze planeet.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Oorsprong van Wereldmilieudag</h3>
        <p>
          Wereldmilieudag werd voor het eerst gevierd in 1974 en wordt georganiseerd door de Verenigde Naties. 
          Het thema van de dag verandert elk jaar en richt zich op een specifiek milieuprobleem zoals vervuiling, biodiversiteit, of klimaatverandering.
        </p>
        <h3>Thema's en Campagnes</h3>
        <p>
          Elk jaar wordt Wereldmilieudag gevierd met een nieuw thema dat de aandacht vestigt op de meest urgente milieukwesties van dat moment. 
          Wereldwijd worden campagnes en evenementen georganiseerd om mensen aan te moedigen bewuster te leven en actie te ondernemen om de planeet te beschermen.
        </p>
        <h3>Hoe Wereldmilieudag wereldwijd wordt gevierd</h3>
        <p>
          Wereldwijd nemen mensen deel aan activiteiten zoals boomplantacties, opruimcampagnes en bewustwordingsevenementen. 
          Overheden, NGO's, bedrijven en individuen werken samen om een positieve impact te creëren voor het milieu.
        </p>
        <h3>Waarom Wereldmilieudag belangrijk is</h3>
        <p>
          Wereldmilieudag speelt een cruciale rol in het verhogen van het bewustzijn over milieukwesties en inspireert tot duurzame veranderingen. 
          Het moedigt iedereen aan om verantwoordelijkheid te nemen voor hun eigen impact op het milieu en bij te dragen aan een duurzamere toekomst.
        </p>
        <p>
          Of het nu gaat om het verminderen van afval, het beschermen van natuurgebieden of het beperken van de uitstoot van broeikasgassen, 
          Wereldmilieudag is een oproep tot actie om onze planeet voor toekomstige generaties te behouden.
        </p>
      </div>
    </div>
  );
}

export default Wereldmilieudag;