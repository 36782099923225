import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function SinglesDay({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Singles Day | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Singles Day, de grootste online verkoopdag ter wereld, en leer hoe het wordt gevierd." />
  <meta name="keywords" content="Singles Day, online verkoop, aanbiedingen, vrijgezellen, korting, China" />
  <meta property="og:title" content="Singles Day | Vrije Dagen NL" />
  <meta property="og:description" content="Lees alles over Singles Day, de geschiedenis en de grootste kortingsacties." />
  <meta property="og:url" content="https://vrije-dagen.nl/singles%20day" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="singles-day-info">
        <h1>Wat is Singles Day?</h1>
        <p>
          Singles Day, oorspronkelijk afkomstig uit China, is uitgegroeid tot 's werelds grootste dag voor online verkoop. 
          Het wordt gevierd op 11 november en is een commerciële feestdag waarbij vooral vrijgezellen in het zonnetje worden gezet met allerlei aanbiedingen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Singles Day</h3>
        <p>
          Singles Day begon in de jaren 90 in China als een viering van het vrijgezellenbestaan. 
          Het werd snel overgenomen door grote e-commercebedrijven zoals Alibaba, die de dag gebruikten om enorme online kortingsacties te organiseren.
        </p>
        <h3>Grote Online Verkoop en Kortingen</h3>
        <p>
          Tegenwoordig is Singles Day de grootste online winkelgebeurtenis ter wereld, waarbij de verkoop vaak records breekt. 
          Wereldwijd bieden bedrijven kortingen op producten variërend van elektronica tot mode en alles daartussenin.
        </p>
        <h3>Hoe Singles Day te Vieren?</h3>
        <p>
          Veel mensen vieren Singles Day door zichzelf te verwennen met cadeaus en speciale aankopen. 
          Het is ook een tijd om te genieten van vrienden en familie, en soms worden er zelfs vrijgezellenfeestjes georganiseerd.
        </p>
        <h3>De Impact van Singles Day Wereldwijd</h3>
        <p>
          Hoewel het begon als een klein lokaal evenement in China, heeft Singles Day zich verspreid naar veel andere landen over de hele wereld. 
          Het heeft de manier veranderd waarop mensen winkelen en hoe bedrijven marketingstrategieën voor grote verkoopdagen plannen.
        </p>
        <p>
          Singles Day biedt een unieke kans om te profiteren van grote kortingen en aanbiedingen, en het is uitgegroeid tot een wereldwijde feestdag voor koopjesjagers overal.
        </p>
      </div>
    </div>
  );
}

export default SinglesDay;