import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function CyberMonday({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Cyber Monday | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Cyber Monday, de dag vol online kortingen en aanbiedingen na Black Friday." />
  <meta name="keywords" content="Cyber Monday, online kortingen, Black Friday, aanbiedingen, webwinkels" />
  <meta property="og:title" content="Cyber Monday" />
  <meta property="og:description" content="Leer meer over Cyber Monday en hoe je kunt profiteren van de beste online deals." />
  <meta property="og:url" content="https://vrije-dagen.nl/cyber%20monday" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="cyber-monday-info">
        <h1>Wat is Cyber Monday?</h1>
        <p>
          Cyber Monday is een commerciële dag die traditioneel wordt gevierd op de maandag na Black Friday. 
          Het richt zich voornamelijk op online kortingen en aanbiedingen, waarbij veel webwinkels scherpe prijzen hanteren.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Cyber Monday</h3>
        <p>
          De term "Cyber Monday" werd voor het eerst gebruikt in 2005 om de groeiende trend van online winkelen te benadrukken. 
          Het werd geïntroduceerd als een marketingstrategie om mensen aan te moedigen om online aankopen te doen.
        </p>
        <h3>Online Kortingen en Aanbiedingen</h3>
        <p>
          Cyber Monday is dé dag voor online shoppers, met duizenden aanbiedingen die exclusief via het internet beschikbaar zijn. 
          Van elektronica tot mode en huishoudelijke artikelen, de deals zijn vaak net zo goed, zo niet beter, dan op Black Friday.
        </p>
        <h3>Black Friday versus Cyber Monday</h3>
        <p>
          Terwijl Black Friday zich richt op zowel fysieke als online winkels, ligt de focus van Cyber Monday volledig op e-commerce. 
          Dit maakt het een ideale dag voor consumenten die liever vanaf huis winkelen.
        </p>
        <h3>Tips voor het Winkelen op Cyber Monday</h3>
        <p>
          Bereid je voor door van tevoren een lijst te maken van de producten die je wilt kopen en controleer de prijzen al een paar dagen van tevoren. 
          Zo weet je zeker dat je de beste deal krijgt tijdens de kortingsperiode.
        </p>
        <p>
          Cyber Monday biedt een uitstekende kans om online grote kortingen te scoren op allerlei producten. 
          Zorg ervoor dat je goed voorbereid bent om te profiteren van de beste aanbiedingen die deze dag te bieden heeft.
        </p>
      </div>
    </div>
  );
}

export default CyberMonday;