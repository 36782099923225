import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function WereldToerismedag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Wereld Toerismedag | Vrije Dagen NL</title>
  <meta name="description" content="Leer alles over Wereld Toerismedag en het belang van toerisme in onze samenleving." />
  <meta name="keywords" content="Wereld Toerismedag, toerisme, duurzaamheid, culturele uitwisseling, economische ontwikkeling" />
  <meta property="og:title" content="Wereld Toerismedag" />
  <meta property="og:description" content="Ontdek de betekenis van Wereld Toerismedag en de rol van toerisme in economische en culturele ontwikkeling." />
  <meta property="og:url" content="https://vrije-dagen.nl/wereld%20toerismedag" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="wereld-toerismedag-info">
        <h1>Wat is Wereld Toerismedag?</h1>
        <p>
          Wereld Toerismedag, gevierd op 27 september, is een dag die het belang van toerisme benadrukt en zijn impact op economie en cultuur erkent. Het doel is om bewustzijn te creëren over de sociale, culturele, politieke en economische waarde van toerisme.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Betekenis van Toerisme</h3>
        <p>
          Toerisme speelt een cruciale rol in de economische ontwikkeling van veel landen. Het biedt werkgelegenheid, stimuleert de lokale economie en helpt culturele uitwisseling te bevorderen. Wereld Toerismedag herinnert ons eraan hoe belangrijk deze sector is voor duurzame groei.
        </p>
        <h3>Hoe Wereld Toerismedag te Vieren</h3>
        <p>
          Op Wereld Toerismedag worden over de hele wereld evenementen georganiseerd om de voordelen van toerisme te promoten. Mensen worden aangemoedigd om nieuwe plekken te ontdekken, lokaal te reizen en de cultuur en natuur in hun omgeving te waarderen.
        </p>
        <h3>Toerisme en Duurzaamheid</h3>
        <p>
          Een belangrijk thema op Wereld Toerismedag is duurzaamheid. Verantwoord toerisme is cruciaal om de negatieve impact op het milieu te verminderen. Door bewuste keuzes te maken en lokale gemeenschappen te steunen, kunnen we bijdragen aan een duurzamer toeristisch model.
        </p>
        <h3>De Invloed van Toerisme op Cultuur</h3>
        <p>
          Toerisme bevordert niet alleen economische groei, maar speelt ook een rol in het behoud en de promotie van culturele tradities. Het stelt mensen in staat om culturele diversiteit te ontdekken en te waarderen, wat leidt tot meer begrip en respect tussen verschillende gemeenschappen.
        </p>
      </div>
    </div>
  );
}

export default WereldToerismedag;