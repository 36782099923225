import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../SearchFilter.css';

function SchoolHolidayFilter({ onFilter, defaultFilters }) {
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState([]);
  const [vakantieOptions, setVakantieOptions] = useState([]);

  useEffect(() => {
    const yearOptions = [];
    for (let i = 0; i <= 6; i++) {  // Allow a range of 5 years after the current year
      yearOptions.push(currentYear + i);
    }
    setYears(yearOptions);

    // Fetch the unique schoolvakantie values from db.json
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const uniqueVakanties = [...new Set(response.data.schoolHolidays.map(holiday => holiday.name))];
        setVakantieOptions(uniqueVakanties);
      })
      .catch(error => console.error('Error fetching schoolvakantie options:', error));
  }, [currentYear]);

  useEffect(() => {
    // Ensure onFilter is called only once when the component mounts
    if (defaultFilters) {
      onFilter(defaultFilters);
    }
  }, []); // Empty dependency array to run only once when the component mounts

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    onFilter({ [name]: value });
  };

  return (
    <div className="filter-container mb-3 d-flex flex-wrap">
      <select name="year" className="form-control mr-2 mb-2" value={defaultFilters.year || ''} onChange={handleFilterChange}>
        <option value="">Alle jaren</option>
        {years.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <select name="region" className="form-control mr-2 mb-2" value={defaultFilters.region || ''} onChange={handleFilterChange}>
        <option value="">Alle regio's</option>
        <option value="Noord">Noord</option>
        <option value="Midden">Midden</option>
        <option value="Zuid">Zuid</option>
        <option value="Heel Nederland">Heel Nederland</option>
      </select>
      <select name="vakantie" className="form-control mr-2 mb-2" value={defaultFilters.vakantie || ''} onChange={handleFilterChange}>
        <option value="">Alle vakanties</option>
        {vakantieOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default SchoolHolidayFilter;