import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CalendarIntegration from '../CalendarIntegration';

function HolidayTable({ holidays }) {
  const sortedHolidays = [...holidays].sort((a, b) => new Date(a.date) - new Date(b.date));

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nl-NL', options);
  };

  const getDayOfWeek = (dateString) => {
    const options = { weekday: 'long' };
    return new Date(dateString).toLocaleDateString('nl-NL', options);
  };

  return (
    <>
      <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Feestdag</th>
            <th>Dag v/d Week</th>
            <th>Datum</th>
            <th>Officieel</th>
            <th>Kalender</th>
          </tr>
        </thead>
        <tbody>
          {sortedHolidays.length > 0 ? (
            sortedHolidays.map((holiday) => (
              <tr key={holiday.id}>
                <td><Link to={`/${holiday.name.toLowerCase()}`}>{holiday.name}</Link></td>
                <td>{getDayOfWeek(holiday.date)}</td>
                <td>{formatDate(holiday.date)}</td>
                <td>{holiday.official ? 'Yes' : 'No'}</td>
                <td><CalendarIntegration holiday={holiday} /></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No holidays found.</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      <div className="container">
        <h2>Vrij op Feestdagen? Check Altijd je CAO en Arbeidsovereenkomst!</h2>
        <p>
          Wist je dat er geen wet is die je recht geeft op een vrije dag tijdens feestdagen? Of je vrij bent, hangt helemaal af van wat er in je cao of arbeidsovereenkomst staat. Dus, vergeet niet om deze documenten goed te bekijken om te weten waar je aan toe bent!
        </p>
        <p>Meer weten over <Link to="/schoolvakanties">schoolvakanties in Nederland</Link>? Bekijk onze pagina over schoolvakanties.</p>
   
      </div>
    </>
  );
}

HolidayTable.propTypes = {
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      official: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default HolidayTable;
