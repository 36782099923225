import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 Vrije Dagen NL. Alle rechten voorbehouden.</p>
        <p><Link to="/disclaimer">Disclaimer en Cookiebeleid</Link></p>
      </div>
    </footer>
  );
}

export default Footer;
