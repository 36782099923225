import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Vaderdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Vaderdag | Vaderdag Nederland</title>
  <meta name="description" content="Ontdek alles over Vaderdag, de geschiedenis en leuke manieren om je vader in het zonnetje te zetten." />
  <meta name="keywords" content="Vaderdag, vaders, feestdag, cadeau-ideeën, geschiedenis, vieren" />
  <meta property="og:title" content="Vaderdag - Eer de vaders van Nederland" />
  <meta property="og:description" content="Lees over de geschiedenis van Vaderdag en ontdek leuke ideeën om deze speciale dag te vieren." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/vaderdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="vaderdag-info">
        <h1>Wat is Vaderdag?</h1>
        <p>
          Vaderdag wordt jaarlijks gevierd op de derde zondag van juni en is een speciale dag om alle vaders te eren en te waarderen. 
          Het is een kans voor kinderen en families om hun vaders te laten zien hoeveel ze voor hen betekenen door middel van cadeaus, attenties en gezamenlijke activiteiten.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Vaderdag</h3>
        <p>
          Vaderdag werd voor het eerst gevierd in het begin van de 20e eeuw in de Verenigde Staten. De dag werd opgericht om vaders te eren, vergelijkbaar met Moederdag, 
          en is sindsdien wereldwijd verspreid. In Nederland wordt Vaderdag gezien als een dag waarop kinderen hun vaders in het zonnetje zetten.
        </p>
        <h3>Hoe Vaderdag te Vieren</h3>
        <p>
          Op Vaderdag ontvangen vaders vaak cadeautjes zoals handgemaakte knutselwerken van hun kinderen, gadgets, gereedschap of andere geschenken die hen laten zien hoeveel ze gewaardeerd worden.
          Veel families besteden deze dag door samen te zijn, uit eten te gaan of leuke activiteiten te ondernemen die vader leuk vindt.
        </p>
        <h3>Waarom Vaderdag Belangrijk is</h3>
        <p>
          Vaderdag is een belangrijk moment om stil te staan bij de rol die vaders spelen in het gezin en in onze levens. Het is een manier om dankbaarheid te tonen voor hun steun, 
          liefde, begeleiding en de offers die ze dagelijks brengen. Deze dag herinnert ons eraan hoe belangrijk het is om onze vaders te waarderen, niet alleen op deze speciale dag, maar elke dag van het jaar.
        </p>
        <p>
          Vier Vaderdag door je vader te laten zien hoeveel hij voor je betekent. Of je nu kiest voor een simpel gebaar of een groot cadeau, het gaat uiteindelijk om de liefde en waardering die je uitstraalt.
        </p>
      </div>
    </div>
  );
}

export default Vaderdag;