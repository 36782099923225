import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';  // Import the logo

function Header() {
  return (
    <header className="header">
      <Link to="/" className="logo">
        <img src={logo} alt="Vrije Dagen NL" className="logo-image" /> {/* Add the logo */}
        Vrije Dagen NL
      </Link>
      <nav>
      <Link to="/specialedagen">Speciale dagen</Link>
         <div className="dropdown">
         <Link to="/feestdagen">
          <button className="dropbtn">Feestdagen</button>
        </Link>
          <div className="dropdown-content">
            <Link to="/bevrijdingsdag">Bevrijdingsdag</Link>
            <Link to="/koningsdag">Koningsdag</Link>
            <Link to="/goede vrijdag">Goede vrijdag</Link>
            <Link to="/nieuwjaarsdag">Nieuwjaarsdag</Link>
            <Link to="/1ste pinksterdag">1ste pinksterdag</Link>
            <Link to="/2de pinksterdag">2de pinksterdag</Link>
            <Link to="/1ste paasdag">1ste paasdag</Link>
            <Link to="/2de paasdag">2de paasdag</Link>
            <Link to="/oudjaarsdag">Oudjaarsdag</Link>
            <Link to="/hemelvaart">Hemelvaart</Link>
            <Link to="/Suikerfeest">Suikerfeest</Link>
            <Link to="/1ste kerstdag">1ste kerstdag</Link>
            <Link to="/2de kerstdag">2de kerstdag</Link>
          </div>
        </div>       
        <div className="dropdown">
         <Link to="/schoolvakanties">
          <button className="dropbtn">Schoolvakanties</button>
        </Link>
          <div className="dropdown-content">
            <Link to="/bouwvak">Bouwvak</Link>
            <Link to="/herfstvakantie">Herfstvakantie</Link>
            <Link to="/kerstvakantie">Kerstvakantie</Link>
            <Link to="/voorjaarsvakantie">Voorjaarsvakantie</Link>
            <Link to="/meivakantie">Meivakantie</Link>
            <Link to="/zomervakantie">Zomervakantie</Link>
            <Link to="/regios">regio’s</Link>
          </div>
        </div>
        <div className="dropdown">
        <span className="droptxt">Paklijsten</span>
          <div className="dropdown-content">
            <Link to="/Paklijst-zomervakantie">Paklijst zomervakantie</Link>
            <Link to="/Paklijst-wintersport">Paklijst wintersport</Link>
            <Link to="/Paklijst-citytrip">Paklijst citytrip</Link> 
            <Link to="/Paklijst-cruisevakantie">Paklijst cruisevakantie</Link>     
            <Link to="/paklijst-wandelvakantie">Paklijst wandelvakantie</Link>                    
          </div> 
          </div>
          {/* <Link to="/vakantieplanner">Vakantieplanner</Link> */}
          <Link to="/blog">Blog</Link>
          <Link to="/about-us">Over Ons</Link>
      </nav>
    </header>
  );
}

export default Header;
