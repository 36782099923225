import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../SearchFilter.css';

function SpecialeDagenFilter({ onFilter, defaultFilters }) {
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState([]);
  const [holidayOptions, setHolidayOptions] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || {});

  useEffect(() => {
    const yearOptions = [];
    for (let i = 0; i <= 6; i++) {
      yearOptions.push(currentYear + i);
    }
    setYears(yearOptions);

    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`)
      .then(response => {
        const uniqueHolidays = [...new Set(response.data.specialDays.map(holiday => holiday.name))];
        setHolidayOptions(uniqueHolidays);
      })
      .catch(error => console.error('Error fetching holiday options:', error));
  }, []);

  useEffect(() => {
    if (defaultFilters) {
      setFilters(defaultFilters);
      onFilter(defaultFilters);
    }
  }, [ ]);

  const handleFilterChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newFilters = {
      ...filters,
      [name]: type === 'checkbox' ? checked : value,
    };
    setFilters(newFilters);
    onFilter(newFilters);
  };

  return (
    <div className="filter-container mb-3 d-flex flex-wrap">
      <select name="year" className="form-control mr-2 mb-2" value={filters.year || ''} onChange={handleFilterChange}>
        <option value="">Alle jaren</option>
        {years.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <select name="search" className="form-control mr-2 mb-2" value={filters.search || ''} onChange={handleFilterChange}>
        <option value="">Alle speciale dagen</option>
        {holidayOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
      <select name="month" className="form-control mr-2 mb-2" value={filters.month || ''} onChange={handleFilterChange}>
  <option value="">Alle maanden</option>
  <option value="01">Januari</option>
  <option value="02">Februari</option>
  <option value="03">Maart</option>
  <option value="04">April</option>
  <option value="05">Mei</option>
  <option value="06">Juni</option>
  <option value="07">Juli</option>
  <option value="08">Augustus</option>
  <option value="09">September</option>
  <option value="10">Oktober</option>
  <option value="11">November</option>
  <option value="12">December</option>
</select>
    </div>
  );
}

export default SpecialeDagenFilter;
