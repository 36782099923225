import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function PiDag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Pi-dag | Vrije Dagen NL</title>
  <meta name="description" content="Leer alles over Pi-dag, een dag gewijd aan het wiskundige getal Pi. Vier de schoonheid van wiskunde op 14 maart." />
  <meta name="keywords" content="Pi-dag, wiskunde, getal Pi, 14 maart, wiskundige constanten, viering" />
  <meta property="og:title" content="Pi-dag | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek de oorsprong en betekenis van Pi-dag en hoe je deze wiskundige feestdag kunt vieren." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/pi-dag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="pi-dag-info">
        <h1>Pi-dag</h1>
        <p>
          Pi-dag, gevierd op 14 maart, is een speciale dag die is gewijd aan het wiskundige getal Pi (π). Deze dag wordt wereldwijd gevierd door wiskundigen, wetenschappers, en iedereen die dol is op getallen en wiskunde.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Waarom 14 maart?</h3>
        <p>
          De datum 14 maart (3/14 in de Amerikaanse notatie) is gekozen omdat het overeenkomt met de eerste drie cijfers van Pi, namelijk 3,14. Het is een perfecte gelegenheid om wiskundige concepten en het belang van getallen te vieren.
        </p>
        <h3>Wat is Pi?</h3>
        <p>
          Pi (π) is een oneindig getal dat de verhouding uitdrukt tussen de omtrek en de diameter van een cirkel. Het is een van de meest bekende wiskundige constanten en wordt vaak gebruikt in verschillende gebieden van de wiskunde en natuurkunde.
        </p>
        <h3>Hoe vier je Pi-dag?</h3>
        <p>
          Mensen vieren Pi-dag op verschillende manieren, zoals het bakken van taarten (pie), het organiseren van wiskundige quizzen, en het delen van feitjes over getallen. Het is ook een populaire dag in scholen en universiteiten om de schoonheid van wiskunde te promoten.
        </p>
      </div>
    </div>
  );
}

export default PiDag;