import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function NationaleVrijwilligersdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Nationale Vrijwilligersdag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Nationale Vrijwilligersdag in Nederland en hoe vrijwilligers worden gewaardeerd voor hun bijdrage." />
  <meta name="keywords" content="Nationale Vrijwilligersdag, vrijwilligerswerk, Nederland, waardering, gemeenschap" />
  <meta property="og:title" content="Nationale Vrijwilligersdag in Nederland" />
  <meta property="og:description" content="Vier Nationale Vrijwilligersdag in Nederland en toon waardering voor de inzet van vrijwilligers in gemeenschappen." />
  <meta property="og:url" content="https://vrije-dagen.nl/nationale%20vrijwilligersdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="vrijwilligersdag-info">
        <h1>Wat is Nationale Vrijwilligersdag?</h1>
        <p>
          Nationale Vrijwilligersdag wordt gevierd op 7 december en is een dag waarop de inzet van vrijwilligers in Nederland wordt erkend en gevierd. 
          Deze dag biedt een moment om stil te staan bij het onmisbare werk dat vrijwilligers doen voor gemeenschappen, organisaties en goede doelen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Betekenis van Nationale Vrijwilligersdag</h3>
        <p>
          Vrijwilligers zijn de ruggengraat van veel organisaties en gemeenschappen. Op Nationale Vrijwilligersdag bedanken we hen voor hun waardevolle bijdrage en moedigen we anderen aan om ook actief te worden als vrijwilliger.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Op Nationale Vrijwilligersdag worden er door heel Nederland evenementen georganiseerd om vrijwilligers in het zonnetje te zetten. 
          Er zijn bedankbijeenkomsten, workshops en activiteiten die speciaal zijn ontworpen om waardering te tonen voor de inzet van vrijwilligers.
        </p>
        <h3>Hoe je kunt bijdragen</h3>
        <p>
          Vrijwilligerswerk is een prachtige manier om terug te geven aan de gemeenschap. Of je nu tijd vrijmaakt om een lokaal evenement te ondersteunen of je aansluit bij een goed doel, elke bijdrage telt. 
          Nationale Vrijwilligersdag is het perfecte moment om te beginnen met vrijwilligerswerk.
        </p>
        <h3>Waarom Vrijwilligerswerk belangrijk is</h3>
        <p>
          Vrijwilligerswerk versterkt gemeenschappen, bevordert sociale cohesie en biedt kansen voor persoonlijke ontwikkeling. Het is een manier om een positieve impact te maken en bij te dragen aan een betere samenleving.
        </p>
        <p>
          Vier Nationale Vrijwilligersdag door een moment van waardering te tonen voor alle vrijwilligers die het verschil maken. Of je nu zelf vrijwilliger bent of iemand kent die zich inzet, dit is de dag om hen te bedanken voor hun harde werk en toewijding.
        </p>
      </div>
    </div>
  );
}

export default NationaleVrijwilligersdag;