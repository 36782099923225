import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Halloween({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Halloween | Vrije Dagen NL</title>
  <meta name="description" content="Lees alles over Halloween, de tradities, geschiedenis en hoe het wereldwijd wordt gevierd." />
  <meta name="keywords" content="Halloween, feestdag, tradities, griezelfeest, pompoenen, trick-or-treat" />
  <meta property="og:title" content="Halloween | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek alles over de viering van Halloween, van kostuums tot trick-or-treat." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/halloween" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="halloween-info">
        <h1>Wat is Halloween?</h1>
        <p>
          Halloween, gevierd op 31 oktober, is een feestdag waarbij mensen zich verkleden in griezelige kostuums en deelnemen aan activiteiten zoals trick-or-treat, pompoen snijden, en spookhuizen bezoeken.
          De dag staat bekend om zijn griezelige en mysterieuze sfeer, waarin monsters, spoken en heksen centraal staan.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Halloween</h3>
        <p>
          De oorsprong van Halloween gaat terug naar de oude Keltische viering van Samhain, een festival dat het einde van de oogst en het begin van de winter markeerde. 
          Tijdens Samhain geloofden mensen dat de grens tussen de wereld van de levenden en de doden vervaagde, waardoor geesten konden rondwaren op aarde.
        </p>
        <h3>Moderne Tradities</h3>
        <p>
          Tegenwoordig staat Halloween bekend om kostuumfeesten, trick-or-treat, en huizen die zijn versierd met pompoenen en spinnenwebben. 
          Kinderen gaan langs de deuren om snoep te verzamelen, terwijl volwassenen deelnemen aan verkleedfeesten en spookachtige evenementen.
        </p>
        <h3>Decoraties en Symbolen</h3>
        <p>
          Pompoenen, ook wel bekend als jack-o'-lanterns, zijn het bekendste symbool van Halloween. 
          Andere populaire decoraties zijn skeletten, spoken, zwarte katten en spinnenwebben, die allemaal bijdragen aan de griezelige sfeer van het feest.
        </p>
        <h3>Griezelfilms en Verhalen</h3>
        <p>
          Halloween is ook een populaire tijd voor het kijken van griezelfilms en het vertellen van spookverhalen. 
          Van klassieke horrorfilms tot moderne thrillers, de spanning en sensatie van Halloween blijven geliefd bij mensen van alle leeftijden.
        </p>
        <h3>Halloween over de Hele Wereld</h3>
        <p>
          Hoewel Halloween zijn oorsprong vindt in de Keltische landen, wordt het tegenwoordig wereldwijd gevierd. 
          In veel landen worden er optochten, festivals en evenementen georganiseerd, waardoor Halloween een wereldwijd fenomeen is geworden.
        </p>
      </div>
    </div>
  );
}

export default Halloween;