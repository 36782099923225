import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function EerstePaasdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Eerste Paasdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Eerste Paasdag in Nederland en plan je vrije dagen." />
  <meta name="keywords" content="Eerste Paasdag, Pasen, vakantiedagen, Nederland, vrije dagen" />
  <meta property="og:title" content="Eerste Paasdag in Nederland" />
  <meta property="og:description" content="Alles over Eerste Paasdag, de betekenis, vieringen en tradities in Nederland." />
  <meta property="og:url" content="https://vrije-dagen.nl/1ste%20paasdag" />
  <meta property="og:type" content="website" />
</Helmet>

      <div className="eerste-paasdag-info">
        <h1>Wat is Eerste Paasdag?</h1>
        <p>
          Eerste Paasdag, ook wel bekend als Pasen, is een van de belangrijkste christelijke feestdagen en viert de opstanding van Jezus Christus uit de dood. 
          Deze feestdag valt altijd op de eerste zondag na de eerste volle maan in de lente, wat betekent dat de datum elk jaar varieert.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Voor christenen wereldwijd markeert Eerste Paasdag de overwinning van Jezus op de dood en de belofte van eeuwig leven. Het is het hoogtepunt van de Heilige Week, 
          die begint met Palmzondag, gevolgd door Witte Donderdag, Goede Vrijdag en Stille Zaterdag. Op deze dag vieren gelovigen de verrijzenis van Christus met speciale kerkdiensten en vieringen.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          In Nederland wordt Pasen gevierd met kerkdiensten, familiefeesten en een verscheidenheid aan tradities. Veel mensen gaan op zondagochtend naar de kerk voor een paasmis, 
          waarna ze samenkomen met familie en vrienden voor een feestelijke maaltijd. Traditionele gerechten zoals paasbrood, eieren en lam staan vaak op het menu.
        </p>
        <h3>Paaseieren en Paashaas</h3>
        <p>
          Een populaire traditie tijdens Pasen is het zoeken naar paaseieren. Kinderen zoeken naar gekleurde en versierde eieren die de paashaas heeft verstopt in tuinen en parken. 
          Deze eieren symboliseren nieuw leven en wedergeboorte. Het beschilderen en verstoppen van eieren is een leuke activiteit die gezinnen samenbrengt.
        </p>
        <h3>Activiteiten tijdens Pasen</h3>
        <p>
          Naast kerkbezoeken en paaseieren zoeken, zijn er tal van andere activiteiten die tijdens Pasen plaatsvinden. Veel mensen genieten van de lente door naar buiten te gaan voor wandelingen, fietstochten en picknicks. 
          Ook worden er vaak paasmarkten en festivals georganiseerd waar men kan genieten van lokale producten, ambachten en entertainment.
        </p>
        <h3>Pasen en de Voorjaarsvakantie</h3>
        <p>
          Pasen valt vaak samen met de voorjaarsvakantie, wat gezinnen de kans geeft om er een lang weekend of een korte vakantie van te maken. Veel mensen maken gebruik van deze tijd om eropuit te gaan en te genieten van de eerste tekenen van de lente.
        </p>
        <h3>Gemeenschapszin en Liefdadigheid</h3>
        <p>
          Pasen is ook een tijd van gemeenschapszin en liefdadigheid. Veel kerken en organisaties organiseren speciale evenementen om de minderbedeelden te helpen, zoals paasdiners, voedselinzamelingen en liefdadigheidsacties. 
          Het is een moment om te delen en om zorg te dragen voor anderen.
        </p>
        <p>
          Al met al is Eerste Paasdag een dag van vreugde, viering en reflectie. Of je nu deelneemt aan religieuze diensten, geniet van familieactiviteiten of gewoon de lente viert, 
          Pasen biedt een kans om samen te komen en de betekenis van nieuw leven en hoop te herdenken.
        </p>
      </div>
    </div>
  );
}

export default EerstePaasdag;
