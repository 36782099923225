import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleMannendag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Mannendag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Internationale Mannendag, de dag gewijd aan de viering van de bijdragen en het welzijn van mannen wereldwijd." />
  <meta name="keywords" content="Internationale Mannendag, mannen, gezondheid, gendergelijkheid, mannendag, positieve rolmodellen" />
  <meta property="og:title" content="Internationale Mannendag" />
  <meta property="og:description" content="Lees over de betekenis en de doelen van Internationale Mannendag en hoe mannen wereldwijd worden gewaardeerd." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20mannendag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-mannendag-info">
        <h1>Wat is Internationale Mannendag?</h1>
        <p>
          Internationale Mannendag, gevierd op 19 november, is een dag gewijd aan de viering van de bijdragen en het welzijn van mannen wereldwijd. 
          Het doel van deze dag is om aandacht te vragen voor de positieve rol van mannen in de samenleving.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Doelen van Internationale Mannendag</h3>
        <p>
          Internationale Mannendag heeft verschillende doelen, waaronder het bevorderen van gendergelijkheid, het verbeteren van de gezondheid van mannen en jongens, 
          en het erkennen van de positieve rolmodellen die mannen en jongens kunnen zijn in hun gemeenschap en familie.
        </p>
        <h3>Gezondheid en Welzijn</h3>
        <p>
          Een belangrijk aspect van Internationale Mannendag is het bevorderen van de lichamelijke en geestelijke gezondheid van mannen. 
          Het is een gelegenheid om bewustzijn te creëren over gezondheidsproblemen waar mannen mee te maken hebben en om ondersteuning te bieden.
        </p>
        <h3>Viering van Bijdragen</h3>
        <p>
          Deze dag biedt ook de kans om de bijdragen van mannen aan de maatschappij te vieren, van vaderschap en mentorschap tot hun inzet in werk en gemeenschap. 
          Het is een moment om mannen te waarderen voor hun inzet en prestaties.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Wereldwijd worden er verschillende evenementen georganiseerd om Internationale Mannendag te vieren. 
          Denk hierbij aan lezingen, workshops, gezondheidsbeurzen en sportevenementen. Deze activiteiten zijn bedoeld om bewustzijn te vergroten en mannen te motiveren om actief deel te nemen.
        </p>
        <h3>De Rol van Mannen in de Samenleving</h3>
        <p>
          Internationale Mannendag benadrukt ook de rol van mannen in het bevorderen van gendergelijkheid en het ondersteunen van vrouwenrechten. 
          Het moedigt mannen aan om een positieve bijdrage te leveren aan de samenleving en rolmodellen te zijn voor de jongere generaties.
        </p>
      </div>
    </div>
  );
}

export default InternationaleMannendag;