import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function SpecialeDagen({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.specialDays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    } 

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Speciale Dagen in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek een overzicht van speciale dagen in Nederland, zoals Black Friday, Singles Day, en Internationale Vrouwendag. Plan je jaar rond deze bijzondere momenten." />
  <meta name="keywords" content="speciale dagen, Black Friday, Singles Day, Internationale Vrouwendag, Wereldmilieudag, Moederdag, Vaderdag, Nederland" />
  <meta property="og:title" content="Speciale Dagen in Nederland" />
  <meta property="og:description" content="Ontdek een uitgebreid overzicht van speciale dagen in Nederland, van commerciële evenementen tot internationale en familiedagen." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/specialedagen" />
  <meta property="og:type" content="website" />
</Helmet>
<h1>Speciale Dagen in Nederland</h1>
<p>Ontdek een uitgebreid overzicht van speciale dagen in Nederland. Van commerciële evenementen zoals Black Friday en Singles Day.</p>
<p>Bekijk onze lijst van speciale dagen, waaronder internationale dagen zoals Wereldmilieudag en Internationale Vrouwendag, maar ook unieke familiedagen zoals Moederdag en Vaderdag. Plan uw jaar rondom deze bijzondere dagen en mis geen enkele kans om te vieren.</p>
      <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
      <SpecialeDagenTable holidays={filteredHolidays} />
      <AdSenseAd />
    </div>
  );
}

export default SpecialeDagen;
