import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function DagVanDeArbeid({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Dag van de Arbeid | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over de Dag van de Arbeid, de geschiedenis en het belang van deze internationale dag voor de arbeidersbeweging." />
  <meta name="keywords" content="Dag van de Arbeid, 1 mei, arbeidersbeweging, rechten van werknemers, vrije dagen" />
  <meta property="og:title" content="Dag van de Arbeid" />
  <meta property="og:description" content="Lees meer over de betekenis en viering van de Dag van de Arbeid, inclusief de geschiedenis en belangrijke feiten." />
  <meta property="og:url" content="https://vrije-dagen.nl/dag%20van%20de%20arbeid" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="dag-van-de-arbeid-info">
        <h1>Wat is de Dag van de Arbeid?</h1>
        <p>
          De Dag van de Arbeid, ook bekend als Internationale Dag van de Arbeid, is een wereldwijd erkende feestdag die elk jaar op 1 mei wordt gevierd. 
          Het is een dag die gewijd is aan de viering van de bijdragen en prestaties van de arbeidersbeweging en de erkenning van de rechten van werknemers.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van de Dag van de Arbeid</h3>
        <p>
          De Dag van de Arbeid heeft zijn oorsprong in de arbeidersbeweging van de 19e eeuw, die streed voor betere arbeidsomstandigheden, een kortere werkdag en eerlijke lonen. 
          De dag herdenkt vooral de Haymarket-affaire in Chicago in 1886, waar een vreedzame demonstratie voor de achturige werkdag tragisch eindigde in geweld.
        </p>
        <h3>Hoe de Dag van de Arbeid te Vieren</h3>
        <p>
          In veel landen wordt de Dag van de Arbeid gevierd met parades, demonstraties en bijeenkomsten van vakbonden en arbeidersorganisaties. 
          Het is een dag waarop werknemers hun solidariteit tonen en aandacht vragen voor arbeidsrechten en sociale rechtvaardigheid.
        </p>
        <h3>Waarom de Dag van de Arbeid Belangrijk is</h3>
        <p>
          De Dag van de Arbeid is van groot belang omdat het de voortdurende strijd voor betere arbeidsomstandigheden, eerlijke lonen en gelijke rechten voor werknemers over de hele wereld benadrukt. 
          Het herinnert ons eraan hoe belangrijk het is om de rechten van arbeiders te blijven verdedigen en te versterken.
        </p>
        <p>
          Of het nu gaat om het vieren van behaalde successen of het benadrukken van huidige uitdagingen in de arbeidswereld, de Dag van de Arbeid blijft een cruciale dag voor arbeiders wereldwijd. 
          Het is een moment van reflectie, bewustwording en actie om te werken aan een rechtvaardigere toekomst voor iedereen.
        </p>
      </div>
    </div>
  );
}

export default DagVanDeArbeid;