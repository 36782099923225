import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Bevrijdingsdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      
      <AdSenseAd />
      <div className="bevrijdingsdag-info">
      <Helmet>
  <title>Bevrijdingsdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Bevrijdingsdag in Nederland, de nationale feestdag ter herdenking van de bevrijding in 1945." />
  <meta name="keywords" content="Bevrijdingsdag, vrijheid, Nederland, nationale feestdag, herdenking" />
  <meta property="og:title" content="Bevrijdingsdag in Nederland" />
  <meta property="og:description" content="Leer meer over de betekenis en vieringen van Bevrijdingsdag in Nederland." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/bevrijdingsdag" />
  <meta property="og:type" content="website" />
</Helmet>
        <h1>Wat is Bevrijdingsdag?</h1>
        <p>
          Bevrijdingsdag, gevierd op 5 mei, is een nationale feestdag in Nederland ter herdenking van de bevrijding van de Duitse bezetting in 1945. 
          Het markeert het einde van de Tweede Wereldoorlog voor Nederland en wordt beschouwd als een dag van vrijheid en vrede.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <h3>De Geschiedenis van Bevrijdingsdag</h3>
        <p>
          Bevrijdingsdag werd voor het eerst gevierd in 1945, direct na de bevrijding. Sindsdien is het een jaarlijks terugkerend evenement dat de bevrijding van Nederland herdenkt en viert. 
          De dag is een herinnering aan de offers die zijn gebracht voor vrijheid en een viering van de waarden van vrijheid en democratie.
        </p>
        <h3>Vieringen en Evenementen</h3>
        <p>
          Bevrijdingsdag wordt door het hele land gevierd met tal van evenementen en activiteiten. Er zijn officiële ceremonies, muziekfestivals, culturele evenementen en tal van lokale festiviteiten. 
          De dag begint vaak met een nationale ceremonie waarbij de premier en andere hoogwaardigheidsbekleders aanwezig zijn.
        </p>
        <p>
          Een van de hoogtepunten van Bevrijdingsdag zijn de Bevrijdingsfestivals die in verschillende steden worden georganiseerd. Deze festivals trekken duizenden bezoekers en bieden een podium aan bekende artiesten, sprekers en activiteiten voor alle leeftijden.
        </p>
        <h3>Vrijheidsmaaltijden</h3>
        <p>
          Een andere mooie traditie op Bevrijdingsdag zijn de Vrijheidsmaaltijden. Deze maaltijden worden in het hele land georganiseerd en brengen mensen samen om te praten over vrijheid en de waarde ervan in onze samenleving. 
          Het is een moment van reflectie en verbinding, waarbij verhalen worden gedeeld en nieuwe vriendschappen ontstaan.
        </p>
        <h3>Vuur Bevrijdingsvuur</h3>
        <p>
          Het ontsteken van het Bevrijdingsvuur is een belangrijk symbool van Bevrijdingsdag. Het vuur wordt 's nachts aangestoken in Wageningen, waar de Duitse capitulatie werd getekend, en vervolgens door lopers verspreid over het hele land. 
          Dit ritueel symboliseert de verspreiding van vrijheid en de verbondenheid van alle Nederlanders in de viering van hun bevrijding.
        </p>
        <h3>Herdenking en Viering</h3>
        <p>
          Bevrijdingsdag is zowel een dag van herdenking als van viering. Het is een dag om stil te staan bij de geschiedenis en de offers die zijn gebracht voor onze vrijheid, maar ook een dag om te vieren dat we in vrijheid leven. 
          Deze dualiteit maakt Bevrijdingsdag tot een unieke en betekenisvolle feestdag.
        </p>
        <h3>Een Dag van Nationale Eenheid</h3>
        <p>
          Bevrijdingsdag brengt mensen van alle leeftijden en achtergronden samen. Het is een dag van nationale eenheid en trots, waarbij iedereen de kans krijgt om de waarde van vrijheid te vieren en te herdenken. 
          Of je nu deelneemt aan een festival, een Vrijheidsmaaltijd organiseert of het Bevrijdingsvuur ziet branden, Bevrijdingsdag is een dag die ons herinnert aan het belang van vrijheid en de verantwoordelijkheid die we hebben om deze te koesteren.
        </p>
      </div>
    </div>
  );
}

export default Bevrijdingsdag;
