import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Grootouderdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Grootouderdag | Vrije Dagen NL</title>
  <meta name="description" content="Leer meer over Grootouderdag en het belang van grootouders in het gezin." />
  <meta name="keywords" content="Grootouderdag, grootouders, familie, waardering, tradities" />
  <meta property="og:title" content="Grootouderdag | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek de geschiedenis van Grootouderdag en hoe je deze speciale dag kunt vieren met je familie." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/grootouderdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="grootouderdag-info">
        <h1>Wat is Grootouderdag?</h1>
        <p>
          Grootouderdag is een speciale dag die gewijd is aan het eren en waarderen van grootouders en hun belangrijke rol in het gezin. 
          Het is een gelegenheid om stil te staan bij de liefde, steun en wijsheid die grootouders aan hun kleinkinderen geven.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Grootouderdag</h3>
        <p>
          Grootouderdag is ontstaan als een manier om grootouders te bedanken voor hun rol in het leven van hun kleinkinderen. 
          In veel landen wordt deze dag op verschillende data gevierd, maar het doel blijft overal hetzelfde: grootouders in het zonnetje zetten en hun bijdragen aan het gezin erkennen.
        </p>
        <h3>Hoe Grootouderdag te Vieren</h3>
        <p>
          Op Grootouderdag organiseren families vaak bijeenkomsten of uitstapjes om tijd door te brengen met grootouders. 
          Het is ook gebruikelijk om cadeautjes of bloemen te geven als blijk van waardering. Kleinkinderen maken soms zelfgemaakte kaarten of knutselwerkjes voor hun opa's en oma's.
        </p>
        <h3>Waarom Grootouderdag Belangrijk is</h3>
        <p>
          Grootouders spelen een unieke rol in het gezin door het bieden van liefde, steun en advies. Ze zijn vaak een bron van familiegeschiedenis en tradities. 
          Grootouderdag biedt een kans om deze bijzondere band te vieren en grootouders te laten weten hoe belangrijk ze zijn in het leven van hun kleinkinderen.
        </p>
        <p>
          Of het nu gaat om het delen van verhalen, het bakken van koekjes of gewoon samen lachen, grootouders zijn onvervangbaar. 
          Laten we hen op Grootouderdag en elke dag bedanken voor de onvoorwaardelijke liefde en wijsheid die ze met ons delen.
        </p>
      </div>
    </div>
  );
}

export default Grootouderdag;