import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Wereldgezondheidsdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
    <title>Wereldgezondheidsdag | Vrije Dagen NL</title>
    <meta name="description" content="Ontdek alles over Wereldgezondheidsdag, een dag gewijd aan het vergroten van bewustzijn over wereldwijde gezondheidskwesties." />
    <meta name="keywords" content="Wereldgezondheidsdag, gezondheid, Wereldgezondheidsorganisatie, WHO, bewustzijn, gezondheidskwesties" />
    <meta property="og:title" content="Wereldgezondheidsdag" />
    <meta property="og:description" content="Kom meer te weten over Wereldgezondheidsdag en hoe je kunt bijdragen aan wereldwijde gezondheidsinitiatieven." />
    <meta property="og:url" content="https://www.vrije-dagen.nl/wereldgezondheidsdag" />
    <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="wereldgezondheidsdag-info">
        <h1>Wat is Wereldgezondheidsdag?</h1>
        <p>
          Wereldgezondheidsdag, gevierd op 7 april, is een internationale dag die bedoeld is om het bewustzijn over wereldwijde gezondheidskwesties te vergroten. 
          Deze dag richt zich op het bevorderen van een gezonde levensstijl en het aanpakken van gezondheidsproblemen die wereldwijd van invloed zijn op gemeenschappen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Oorsprong van Wereldgezondheidsdag</h3>
        <p>
          Wereldgezondheidsdag werd in 1948 in het leven geroepen door de Wereldgezondheidsorganisatie (WHO) om het belang van gezondheid en welzijn wereldwijd te benadrukken. 
          Elk jaar wordt een specifiek gezondheidsprobleem onder de aandacht gebracht om bewustwording te creëren en actie te stimuleren.
        </p>
        <h3>Thema van Wereldgezondheidsdag</h3>
        <p>
          Ieder jaar staat Wereldgezondheidsdag in het teken van een ander thema dat verband houdt met gezondheidsuitdagingen. Deze thema's variëren van 
          universele gezondheidszorg tot de bestrijding van ziektes zoals diabetes en malaria. Het doel is om actie te ondernemen en oplossingen te vinden voor gezondheidsproblemen.
        </p>
        <h3>Evenementen en Campagnes</h3>
        <p>
          Wereldwijd worden er op Wereldgezondheidsdag evenementen, workshops en campagnes georganiseerd om mensen bewust te maken van hun gezondheid en welzijn. 
          Deze activiteiten bevorderen een gezonde levensstijl en geven voorlichting over het voorkomen van ziektes.
        </p>
        <h3>Hoe kunt u bijdragen?</h3>
        <p>
          U kunt deelnemen aan activiteiten op Wereldgezondheidsdag door deel te nemen aan gezondheidsevenementen, bewustwordingscampagnes te steunen en een gezonde levensstijl te bevorderen. 
          Kleine veranderingen in uw dagelijkse leven kunnen een grote impact hebben op uw gezondheid en die van uw omgeving.
        </p>
        <h3>Gezondheid als Prioriteit</h3>
        <p>
          Wereldgezondheidsdag herinnert ons eraan dat gezondheid een fundamenteel mensenrecht is en dat we allemaal een rol spelen in het bevorderen van een gezondere wereld. 
          Samen kunnen we een positieve impact hebben op de gezondheid van onze gemeenschappen.
        </p>
      </div>
    </div>
  );
}

export default Wereldgezondheidsdag;