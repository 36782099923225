import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagVanDeVriendschap({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van de Vriendschap | Vrije Dagen NL</title>
  <meta name="description" content="Vier de Internationale Dag van de Vriendschap en ontdek het belang van vriendschap in onze samenleving." />
  <meta name="keywords" content="Internationale Dag van de Vriendschap, vriendschap, 30 juli, speciale dagen, viering" />
  <meta property="og:title" content="Internationale Dag van de Vriendschap" />
  <meta property="og:description" content="Ontdek hoe de Internationale Dag van de Vriendschap bijdraagt aan het versterken van banden tussen mensen wereldwijd." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20de%20vriendschap" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-vriendschapsdag-info">
        <h1>Wat is Internationale Dag van de Vriendschap?</h1>
        <p>
          De Internationale Dag van de Vriendschap, gevierd op 30 juli, is een dag gewijd aan het versterken van vriendschapsbanden 
          en het bevorderen van begrip en samenwerking tussen mensen en gemeenschappen wereldwijd.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Betekenis van Vriendschap</h3>
        <p>
          Vriendschap is een van de meest waardevolle relaties die we in ons leven kunnen hebben. Het is gebaseerd op wederzijds vertrouwen, begrip en respect, 
          en het helpt ons door de moeilijkste tijden heen. Op deze dag worden mensen aangemoedigd om de banden met hun vrienden te versterken.
        </p>
        <h3>Hoe de Dag wordt Gevierd</h3>
        <p>
          Wereldwijd worden er evenementen en activiteiten georganiseerd om vriendschap te vieren. Van het sturen van kaarten en cadeautjes tot het organiseren van bijeenkomsten 
          met vrienden, mensen over de hele wereld nemen deel aan verschillende activiteiten om hun vriendschapsbanden te vieren.
        </p>
        <h3>Het Belang van Vriendschap in de Samenleving</h3>
        <p>
          Vriendschap speelt een cruciale rol in het bevorderen van vrede en harmonie in de samenleving. Het helpt barrières te doorbreken en creëert een cultuur van acceptatie en begrip 
          tussen verschillende groepen en gemeenschappen.
        </p>
        <h3>Inspiratie om Nieuwe Vriendschappen te Sluiten</h3>
        <p>
          De Internationale Dag van de Vriendschap herinnert ons eraan dat we open moeten staan voor nieuwe relaties. Het is een kans om nieuwe mensen te ontmoeten en vriendschappen op te bouwen 
          die ons leven kunnen verrijken.
        </p>
        <h3>Vriendschap als Kracht voor Verandering</h3>
        <p>
          Samen kunnen vrienden een grote impact hebben op de wereld. Door elkaar te steunen en te motiveren, kunnen vrienden bijdragen aan positieve veranderingen in hun gemeenschappen 
          en de wereld om hen heen.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagVanDeVriendschap;