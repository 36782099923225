// src/components/HeroBanner.js

import React from 'react';
import './HeroBanner.css'; // Create this file for styling the banner

const HeroBanner = () => {
  return (
    <div className="hero-banner">
      <img src={`${process.env.PUBLIC_URL}/images/hero-vakantie.jpg`} alt="Hero Banner" className="hero-image" />
    </div>
  );
};

export default HeroBanner;
