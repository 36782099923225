import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Dierendag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Dierendag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Dierendag, een speciale dag gewijd aan dierenwelzijn op 4 oktober." />
  <meta name="keywords" content="Dierendag, dierenwelzijn, dieren, speciale dagen, 4 oktober" />
  <meta property="og:title" content="Dierendag" />
  <meta property="og:description" content="Leer meer over Dierendag en hoe je deze speciale dag voor dieren kunt vieren." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/dierendag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="dierendag-info">
        <h1>Wat is Dierendag?</h1>
        <p>
          Dierendag, gevierd op 4 oktober, is een speciale dag die wereldwijd in het teken staat van dierenwelzijn. 
          Op deze dag worden huisdieren en dieren in het algemeen extra in het zonnetje gezet, met als doel meer aandacht te vestigen op hun welzijn en rechten.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Dierendag</h3>
        <p>
          De oorsprong van Dierendag gaat terug naar 1931 toen deze dag werd geïnitieerd tijdens een internationale conferentie van ecologen in Florence, Italië.
          Sindsdien is het wereldwijd uitgegroeid tot een dag om dieren te eren en de aandacht te vestigen op hun bescherming en welzijn.
        </p>
        <h3>Hoe Dierendag Wordt Gevierd</h3>
        <p>
          Op Dierendag worden veel huisdieren extra verwend met speciale traktaties, speelgoed, en aandacht. 
          Ook wordt deze dag gebruikt om het bewustzijn te vergroten over de situatie van dieren in het wild en huisdieren zonder een thuis.
        </p>
        <h3>De Belangrijkheid van Dierenwelzijn</h3>
        <p>
          Dierendag herinnert ons eraan hoe belangrijk het is om respect en zorg voor alle levende wezens te tonen. 
          Van huisdieren tot dieren in de natuur, hun welzijn is cruciaal voor het behoud van ecosystemen en onze menselijke samenleving.
        </p>
        <p>
          Deze dag biedt ook een gelegenheid om het bewustzijn te vergroten over problemen zoals dierenmishandeling, bedreigde diersoorten en de noodzaak van milieubehoud. 
          Het is een oproep aan mensen over de hele wereld om actie te ondernemen voor het welzijn van alle dieren.
        </p>
      </div>
    </div>
  );
}

export default Dierendag;