import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Moederdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Moederdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Moederdag in Nederland, inclusief manieren om deze speciale dag te vieren en de geschiedenis ervan." />
  <meta name="keywords" content="Moederdag, moeder, feestdag, Nederland, familie, cadeau, liefde" />
  <meta property="og:title" content="Moederdag in Nederland" />
  <meta property="og:description" content="Lees meer over Moederdag in Nederland en hoe je deze speciale dag kunt vieren met je familie." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/moederdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="moederdag-info">
        <h1>Wat is Moederdag?</h1>
        <p>
          Moederdag wordt jaarlijks gevierd op de tweede zondag van mei en is een speciale dag om alle moeders te eren en te waarderen. 
          Het is een gelegenheid voor kinderen en families om hun moeders in het zonnetje te zetten en hen te bedanken voor hun onvoorwaardelijke liefde en steun.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Moederdag</h3>
        <p>
          Moederdag vindt zijn oorsprong in de Verenigde Staten en werd populair in het begin van de 20e eeuw. Het werd in het leven geroepen door Anna Jarvis om haar moeder te eren en is inmiddels uitgegroeid tot een wereldwijd fenomeen.
          In Nederland wordt het gevierd als een dag waarop moeders verwend worden met cadeautjes, ontbijt op bed en andere lieve gebaren.
        </p>
        <h3>Hoe Moederdag te Vieren</h3>
        <p>
          Op Moederdag doen kinderen en partners vaak hun best om hun moeders te verrassen met zelfgemaakte cadeaus, bloemen, kaarten en ontbijt op bed. 
          Het is ook een dag waarop veel mensen hun moeders meenemen voor een leuk uitje, zoals een wandeling in het park of een etentje in een restaurant.
        </p>
        <h3>Waarom Moederdag Belangrijk is</h3>
        <p>
          Moederdag is een mooie gelegenheid om stil te staan bij alles wat moeders doen voor hun kinderen en families. Het is een moment van waardering en dankbaarheid voor hun eindeloze inzet, geduld en liefde. 
          Deze dag herinnert ons eraan hoe belangrijk het is om onze moeders niet alleen op deze dag, maar het hele jaar door te koesteren en te waarderen.
        </p>
        <p>
          Vier Moederdag met een speciaal gebaar en laat je moeder weten hoeveel ze voor je betekent. Of je nu kiest voor een groot cadeau of een simpel maar oprecht bedankje, 
          het gaat uiteindelijk om het tonen van je liefde en waardering.
        </p>
      </div>
    </div>
  );
}

export default Moederdag;