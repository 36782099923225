import React from 'react';
import { Link } from 'react-router-dom';
import CalendarIntegration from '../CalendarIntegration';

function SchoolHolidayTable({ holidays }) {
  const sortedHolidays = [...holidays].sort((a, b) => new Date(a.from) - new Date(b.from));

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nl-NL', options);
  };

  const combineHolidays = (holidays) => {
    const combinedHolidays = [];

    holidays.forEach((holiday) => {
      const existingHoliday = combinedHolidays.find(
        (h) => h.name === holiday.name && h.from === holiday.from && h.to === holiday.to && h.week === holiday.week
      );

      if (existingHoliday) {
        if (!existingHoliday.regions.includes(holiday.region)) {
          existingHoliday.regions.push(holiday.region);
        }
      } else {
        combinedHolidays.push({
          ...holiday,
          regions: [holiday.region],
        });
      }
    });

    combinedHolidays.forEach((holiday) => {
      if (holiday.regions.includes('Noord') && holiday.regions.includes('Midden') && holiday.regions.includes('Zuid')) {
        holiday.region = 'Heel Nederland';
      } else {
        holiday.region = holiday.regions.join(' en ');
      }
      delete holiday.regions;
    });

    return combinedHolidays;
  };

  const combinedHolidays = combineHolidays(sortedHolidays);


  return (
    <>
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Schoolvakantie</th>
            <th>Regio</th>
            <th>Van</th>
            <th>Tot</th>
            <th>Weeknummer</th>
            <th>Kalender</th>
          </tr>
        </thead>
        <tbody>
          {combinedHolidays.length > 0 ? (
            combinedHolidays.map((holiday) => (
              <tr key={`${holiday.name}-${holiday.from}-${holiday.to}`}>
                <td><Link to={`/${holiday.name.toLowerCase()}`}>{holiday.name}</Link></td>
                <td>{holiday.region}</td>
                <td>{formatDate(holiday.from)}</td>
                <td>{formatDate(holiday.to)}</td>
                <td>{holiday.week}</td>
                <td><CalendarIntegration holiday={holiday} /></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Geen schoolvakanties gevonden.</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      <div>
        <p>Meer weten over <Link to="/feestdagen">feestdagen in Nederland</Link>? Bekijk onze pagina over de feestdagen.</p>
        <p>Meer informatie over de regios <Link to="/regios">Regios in Nederland</Link>? Bekijk onze pagina over de regios.</p>
      </div>
    </>
  );
}

export default SchoolHolidayTable;