import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Nieuwjaarsdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Nieuwjaarsdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Alles over Nieuwjaarsdag in Nederland: tradities, activiteiten, en hoe deze dag gevierd wordt." />
  <meta name="keywords" content="Nieuwjaarsdag, vrije dagen, feestdagen, Nederland, tradities" />
  <meta property="og:title" content="Nieuwjaarsdag in Nederland" />
  <meta property="og:description" content="Ontdek de tradities en activiteiten rondom Nieuwjaarsdag in Nederland." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/nieuwjaarsdag" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="nieuwjaarsdag-info">
        <h1>Wat is Nieuwjaarsdag?</h1>
        <p>
          Na het feestgedruis van Oudjaarsavond, markeert Nieuwjaarsdag een nieuw begin. Het is een dag van rust, reflectie en traditie. 
          Veel mensen beginnen de dag rustig, vaak met een uitgebreid ontbijt of brunch met overgebleven oliebollen en appelbeignets.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <AdSenseAd />
        <h3>Rust en Herstel</h3>
        <p>
          Voor velen begint Nieuwjaarsdag rustig. Na een nacht vol feest, vuurwerk en laat naar bed gaan, is het eerste deel van de dag vaak gewijd aan uitslapen en herstellen.
          Ontbijten met het gezin of vrienden, vaak nog in pyjama, en genieten van een rustige start van het jaar is gebruikelijk.
        </p>
        <h3>Nieuwjaarsduik</h3>
        <p>
          Een van de meest opvallende tradities op Nieuwjaarsdag is de Nieuwjaarsduik. In heel Nederland, maar vooral in Scheveningen, verzamelen duizenden mensen zich om het koude water in te duiken.
          Dit verfrissende ritueel symboliseert een frisse start en wordt vaak gezien als een manier om het nieuwe jaar energiek en verkwikt te beginnen.
        </p>
        <h3>Familiebezoeken en Gezelligheid</h3>
        <p>
          Nieuwjaarsdag is ook een dag voor familiebezoeken. Veel mensen brengen een deel van de dag door met het bezoeken van familieleden en vrienden om hen een gelukkig nieuwjaar te wensen.
          Dit versterkt de band met geliefden en zet een warme, sociale toon voor het komende jaar.
        </p>
        <h3>Nieuwjaarsconcert</h3>
        <p>
          Een andere geliefde traditie is het kijken naar het Nieuwjaarsconcert van de Wiener Philharmoniker, dat wereldwijd wordt uitgezonden.
          Dit concert, gevuld met lichte klassieke muziek en walsen van de Strauss-familie, is een elegante manier om de eerste dag van het jaar door te brengen.
        </p>
        <h3>Reflectie en Goede Voornemens</h3>
        <p>
          Nieuwjaarsdag biedt ook een kans voor reflectie. Veel mensen nemen de tijd om na te denken over het afgelopen jaar en om goede voornemens te formuleren voor het nieuwe jaar.
          Dit kan variëren van persoonlijke doelen zoals gezonder leven en meer sporten, tot professionele ambities en nieuwe hobby's.
        </p>
        <h3>Eten en Drinken</h3>
        <p>
          Naast oliebollen en appelbeignets, zijn er andere traditionele lekkernijen die op Nieuwjaarsdag gegeten worden.
          In sommige regio's van Nederland is het gebruikelijk om nieuwjaarsrolletjes of kniepertjes te eten. Deze dunne, krokante wafels symboliseren de opgerolde gebeurtenissen van het oude jaar.
        </p>
        <h3>Sportieve Evenementen</h3>
        <p>
          Voor de actievelingen onder ons zijn er op Nieuwjaarsdag verschillende sportieve evenementen en wandelingen georganiseerd.
          Van nieuwjaarslopen tot winterwandelingen, deze activiteiten bieden een gezonde start van het jaar en zijn een leuke manier om de buitenlucht in te gaan.
        </p>
        <p>
          Nieuwjaarsdag in Nederland is veelzijdig en biedt voor ieder wat wils. Of je nu kiest voor een rustige dag thuis, een frisse duik in de zee, een cultureel concert of een sportieve activiteit,
          het belangrijkste is dat de dag een positieve en hoopvolle start van het nieuwe jaar markeert. Het is een tijd om samen te zijn met dierbaren, nieuwe doelen te stellen en te genieten van de kleine momenten die het leven bijzonder maken.
        </p>
      </div>
    </div>
  );
}

export default Nieuwjaarsdag;
