import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Wereldboekendag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Wereldboekendag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Wereldboekendag en de betekenis van lezen en literatuur." />
  <meta name="keywords" content="Wereldboekendag, lezen, boeken, literatuur, auteursrechten" />
  <meta property="og:title" content="Wereldboekendag" />
  <meta property="og:description" content="Vier Wereldboekendag en ontdek het belang van lezen en het beschermen van auteursrechten." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/wereldboekendag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="wereldboekendag-info">
        <h1>Wat is Wereldboekendag?</h1>
        <p>
          Wereldboekendag, gevierd op 23 april, is een speciale dag die wereldwijd is gewijd aan het promoten van lezen, publiceren, en de bescherming van auteursrechten. 
          Het doel van deze dag is om mensen te inspireren om meer te lezen en het belang van boeken en literatuur in onze samenleving te waarderen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Betekenis van Lezen</h3>
        <p>
          Lezen speelt een cruciale rol in onze educatie en persoonlijke ontwikkeling. Wereldboekendag moedigt mensen van alle leeftijden aan om de magie van boeken te ontdekken 
          en zich te verdiepen in verhalen die hun wereldbeeld kunnen verrijken.
        </p>
        <h3>Hoe Wereldboekendag te Vieren</h3>
        <p>
          Op Wereldboekendag worden er wereldwijd evenementen en activiteiten georganiseerd om het lezen te bevorderen. Denk aan boekenbeurzen, lezingen, workshops en de uitwisseling van boeken onder vrienden en familie.
        </p>
        <h3>Bescherming van Auteursrechten</h3>
        <p>
          Naast het promoten van lezen, is Wereldboekendag ook een moment om stil te staan bij de rechten van auteurs en het belang van het beschermen van hun intellectuele eigendom. 
          Auteursrechten zorgen ervoor dat schrijvers eerlijk worden beloond voor hun creativiteit en bijdragen aan de literatuur.
        </p>
      </div>
    </div>
  );
}

export default Wereldboekendag;