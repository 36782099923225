import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Suikerfeest({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    } 

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
<Helmet>
  <title>Suikerfeest in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over het Suikerfeest in Nederland, inclusief tradities en vieringen van Eid al-Fitr." />
  <meta name="keywords" content="Suikerfeest, Eid al-Fitr, islamitische feestdagen, Nederland, Ramadan, moslim feestdag" />
  <meta property="og:title" content="Suikerfeest in Nederland" />
  <meta property="og:description" content="Leer meer over de viering van het Suikerfeest in Nederland, inclusief de religieuze betekenis en tradities." />
  <meta property="og:url" content="https://www.vrije-dagen.nl/suikerfeest" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="suikerfeest-info">
        <h1>Wat is het Suikerfeest?</h1>
        <p>
          Het Suikerfeest, ook bekend als Eid al-Fitr, is een belangrijke religieuze feestdag voor moslims wereldwijd. 
          Het markeert het einde van de Ramadan, de islamitische heilige maand van vasten, en wordt gevierd met gebeden, feestmaaltijden en gemeenschapsactiviteiten.
        </p>
        <AdSenseAd />
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <h3>Religieuze Betekenis</h3>
        <p>
          Eid al-Fitr betekent "het feest van het breken van het vasten" en is een dag van vreugde en dankbaarheid. 
          Moslims beginnen de dag met een speciaal gebed, het Eid-gebed, dat meestal wordt gehouden in moskeeën of grote open ruimtes. 
          Het is een tijd om God te danken voor de kracht en het geduld tijdens de vastenmaand en om vergeving en zegeningen te vragen.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          Na het Eid-gebed komen families en vrienden samen om te genieten van feestelijke maaltijden. Traditionele gerechten en zoetigheden worden bereid en gedeeld. 
          Het is ook gebruikelijk om nieuwe kleren te dragen en huizen te versieren om de feestelijke sfeer te benadrukken. Kinderen ontvangen vaak cadeautjes en geld als onderdeel van de viering.
        </p>
        <h3>Gemeenschapszin en Liefdadigheid</h3>
        <p>
          Het Suikerfeest is ook een tijd van liefdadigheid en gemeenschapszin. Moslims worden aangemoedigd om zakat al-fitr te geven, 
          een verplichte liefdadigheid die wordt gegeven aan de armen en behoeftigen om hen in staat te stellen deel te nemen aan de festiviteiten. 
          Het is een manier om de gemeenschapsbanden te versterken en te zorgen voor degenen die minder fortuinlijk zijn.
        </p>
        <h3>Activiteiten tijdens het Suikerfeest</h3>
        <p>
          Naast gebeden en maaltijden, zijn er tal van andere activiteiten die tijdens het Suikerfeest plaatsvinden. 
          Veel gemeenschappen organiseren festivals, markten en culturele evenementen waar mensen samenkomen om te vieren. 
          Dit biedt een gelegenheid voor iedereen, ongeacht geloof, om deel te nemen en te leren over de islamitische cultuur en tradities.
        </p>
        <p>
          Al met al is het Suikerfeest een tijd van vreugde, viering en gemeenschapszin. Of je nu deelneemt aan religieuze diensten, geniet van familieactiviteiten 
          of betrokken bent bij liefdadigheidswerk, het Suikerfeest biedt een kans om de betekenis van saamhorigheid en dankbaarheid te ervaren.
        </p>
      </div>
    </div>
  );
}

export default Suikerfeest;
