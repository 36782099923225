import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css';

function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 365 });
    setShowBanner(false);
  };

  const handleManage = () => {
    // Handle manage preferences logic here
    alert('Manage preferences clicked');
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        Wij gebruiken cookies om uw ervaring te verbeteren, voor analytische doeleinden en om u gepersonaliseerde content te tonen. Door op "Accepteren" te klikken, gaat u akkoord met ons gebruik van cookies.
      </p>
      <div className="cookie-buttons">
        <button onClick={handleAccept}>Accepteren</button>
        <button onClick={handleDecline}>Weigeren</button>
        <button onClick={handleManage}>Beheren</button>
      </div>
    </div>
  );
}

export default CookieConsent;
