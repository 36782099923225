import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagVanDeVrede({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van de Vrede | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over de Internationale Dag van de Vrede en hoe je kunt bijdragen aan wereldvrede." />
  <meta name="keywords" content="Internationale Dag van de Vrede, vrede, wereldvrede, evenementen, harmonie, VN" />
  <meta property="og:title" content="Internationale Dag van de Vrede" />
  <meta property="og:description" content="Vier de Internationale Dag van de Vrede en neem deel aan activiteiten om vrede te bevorderen." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20de%20vrede" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="special-day-info">
        <h1>Internationale Dag van de Vrede</h1>
        <p>
          De Internationale Dag van de Vrede, gevierd op 21 september, is een wereldwijde dag die zich richt op de bevordering van vrede tussen mensen en naties. Het is een gelegenheid om stil te staan bij vrede, begrip en samenwerking in onze samenleving.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Betekenis van de Dag</h3>
        <p>
          Deze dag is opgericht door de Verenigde Naties als een oproep tot vrede, zowel binnen als tussen alle naties en mensen. Het biedt de wereld een kans om te focussen op het beëindigen van conflicten en het bevorderen van harmonie.
        </p>
        <h3>Activiteiten en Evenementen</h3>
        <p>
          Op de Internationale Dag van de Vrede worden wereldwijd evenementen georganiseerd, zoals vredesmarsen, bijeenkomsten en ceremonies. Mensen worden aangemoedigd om deel te nemen aan activiteiten die bewustzijn creëren over vrede en geweldloosheid.
        </p>
        <h3>Hoe Kun Jij Meedoen?</h3>
        <p>
          Iedereen kan bijdragen aan de Internationale Dag van de Vrede. Doe mee door een moment van stilte te houden, deel te nemen aan lokale evenementen, of op sociale media berichten te delen over het belang van vrede en samenwerking.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagVanDeVrede;