import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function BlackFriday({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
<Helmet>
  <title>Black Friday | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Black Friday, de grootste dag met kortingen en aanbiedingen wereldwijd." />
  <meta name="keywords" content="Black Friday, kortingen, aanbiedingen, online winkelen, Cyber Monday" />
  <meta property="og:title" content="Black Friday" />
  <meta property="og:description" content="Alles wat je moet weten over Black Friday, inclusief de beste deals en tips voor slim winkelen." />
  <meta property="og:url" content="https://vrije-dagen.nl/black%20friday" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="black-friday-info">
        <h1>Wat is Black Friday?</h1>
        <p>
          Black Friday is een commerciële dag die traditioneel wordt gevierd op de vrijdag na Thanksgiving in de Verenigde Staten. 
          Het is wereldwijd bekend geworden als de dag waarop veel winkels en online platforms enorme kortingen aanbieden op hun producten.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Black Friday</h3>
        <p>
          De term "Black Friday" werd voor het eerst gebruikt in de jaren 1960 in Philadelphia om de chaos te beschrijven die ontstond door de grote drukte in de stad na Thanksgiving. 
          Later kreeg het een positieve connotatie en werd het de dag waarop winkeliers eindelijk winst gingen maken, oftewel "in het zwart" kwamen.
        </p>
        <h3>Kortingen en Aanbiedingen</h3>
        <p>
          Black Friday staat bekend om zijn extreme kortingen en deals, variërend van elektronica en mode tot huishoudelijke apparaten en reisaanbiedingen. 
          Veel consumenten wachten specifiek op deze dag om hun favoriete producten tegen lagere prijzen aan te schaffen.
        </p>
        <h3>Online Shopping en Cyber Monday</h3>
        <p>
          Naast fysieke winkels is Black Friday ook een belangrijke dag voor online retailers. In de afgelopen jaren is de populariteit van online winkelen op deze dag enorm toegenomen. 
          Cyber Monday, de maandag na Black Friday, is specifiek gericht op online aanbiedingen en kortingen.
        </p>
        <h3>Tips voor het Winkelen op Black Friday</h3>
        <p>
          Om het meeste uit Black Friday te halen, is het handig om vooraf onderzoek te doen naar de beste deals en aanbiedingen. 
          Zorg ervoor dat je een budget hebt vastgesteld en vermijd impulsaankopen om je uitgaven onder controle te houden.
        </p>
        <p>
          Black Friday biedt een uitstekende gelegenheid om grote aankopen te doen tegen sterk gereduceerde prijzen. 
          Of je nu op zoek bent naar een nieuwe laptop, kleding, of huishoudelijke apparaten, op Black Friday kun je profiteren van enkele van de beste deals van het jaar.
        </p>
      </div>
    </div>
  );
}

export default BlackFriday;