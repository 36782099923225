import React from 'react';

function Disclaimer() {
  return (
    <div className="container">
      <h2>Disclaimer</h2>

      <h3>Aansprakelijkheid</h3>
      <p>We streven naar correcte informatie, maar kunnen dit niet garanderen. Gebruik van de informatie op deze website is op eigen risico. Wij zijn niet aansprakelijk voor fouten of onderbrekingen.</p>

      <h3>Geen Advies</h3>
      <p>De informatie op deze website is algemeen en niet bedoeld als professioneel advies. Beslissingen op basis van deze informatie zijn op eigen risico.</p>

      <h3>Rechten</h3>
      <p>Alle inhoud op deze website, zoals teksten, afbeeldingen en logo's, is beschermd door auteursrechten. Niets mag zonder schriftelijke toestemming worden gekopieerd.</p>

    </div>
  );
}

export default Disclaimer;
