import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SchoolHolidayFilter from './SchoolHolidayFilter';
import SchoolHolidayTable from './SchoolHolidayTable';
import '../Tables.css';
import AdSenseAd from '../AdSenseAd';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Voorjaarsvakantie({ defaultFilters }) {
  const [schoolHolidays, setSchoolHolidays] = useState([]);
  const [filteredSchoolHolidays, setFilteredSchoolHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/SchoolHolidays.json`, { timeout: 5000 })
      .then(response => {
        const schoolHolidays = response.data.schoolHolidays; // Extract schoolHolidays array
        setSchoolHolidays(schoolHolidays);
        filterHolidays(schoolHolidays, filters);
      })
      .catch(error => console.error('Error fetching school holidays:', error));
  }, []);

  useEffect(() => {
    if (schoolHolidays.length > 0) {
      filterHolidays(schoolHolidays, filters);
    }
  }, [filters, schoolHolidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.from.startsWith(filters.year));
    }
    if (filters.region) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.region.toLowerCase().includes(filters.region.toLowerCase()));
    }
    if (filters.vakantie) {
      updatedHolidays = updatedHolidays.filter(holiday => holiday.name === filters.vakantie);
    }

    setFilteredSchoolHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  return (
    <div className="container">
      <Helmet>
    <title>Voorjaarsvakantie in Nederland | Vrije Dagen NL</title>
    <meta name="description" content="Alles over de Voorjaarsvakantie in Nederland, inclusief data, activiteiten en carnaval in het zuiden." />
    <meta name="keywords" content="Voorjaarsvakantie, krokusvakantie, schoolvakantie, Nederland, carnaval, vrije dagen" />
    <meta property="og:title" content="Voorjaarsvakantie in Nederland" />
    <meta property="og:description" content="Ontdek de Voorjaarsvakantie in Nederland, inclusief activiteiten, data en carnavalsvieringen." />
    <meta property="og:url" content="https://www.vrije-dagen.nl/voorjaarsvakantie" />
    <meta property="og:type" content="website" />
</Helmet>
        <h1>Wat is de Voorjaarsvakantie?</h1>
        <p>
          De Voorjaarsvakantie, ook wel bekend als de krokusvakantie, is een schoolvakantie in Nederland die meestal plaatsvindt in februari. 
          Deze vakantie biedt leerlingen en leraren een pauze in het midden van het schooljaar en valt samen met het begin van de lente.
        </p>
      <div className="voorjaarsvakantie-info">
        <AdSenseAd />
        <h3>Schoolvakantie voor Basisonderwijs en Voortgezet Onderwijs</h3>
        <p>
          De Voorjaarsvakantie geldt voor zowel het basisonderwijs als het voortgezet onderwijs. Het is een kans voor studenten om even bij te komen, 
          op te laden en zich voor te bereiden op de rest van het schooljaar. De exacte data kunnen per regio verschillen.
        </p>
        <SchoolHolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SchoolHolidayTable holidays={filteredSchoolHolidays} />
        <h3>Activiteiten tijdens de Voorjaarsvakantie</h3>
        <p>
          Veel gezinnen gebruiken de Voorjaarsvakantie om op vakantie te gaan, zowel in Nederland als naar bestemmingen in het buitenland. 
          Populaire activiteiten zijn skiën, schaatsen en winterwandelingen in de bergen, maar ook stedentrips en culturele uitstapjes.
        </p>
        <h3>Voorjaarsfestivals en Evenementen</h3>
        <p>
          Tijdens de Voorjaarsvakantie worden er door het hele land verschillende evenementen en festivals georganiseerd. 
          Denk aan carnavalsvieringen, voorjaarsmarkten en speciale tentoonstellingen in musea. Deze evenementen bieden vermaak en activiteiten voor het hele gezin.
        </p>
        <h3>Rust en Ontspanning</h3>
        <p>
          Naast actieve uitstapjes, is de Voorjaarsvakantie ook een tijd voor rust en ontspanning. Veel mensen kiezen ervoor om thuis te blijven, 
          te genieten van de eerste tekenen van de lente en quality time door te brengen met hun gezin.
          <img src={`${process.env.PUBLIC_URL}/images/voorjaarsvakantie-family.jpg`} alt="voorjaarsvakantie familie" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        </p>
        <h3>Voorbereiding op de Rest van het Schooljaar</h3>
        <p>
          De Voorjaarsvakantie biedt ook een moment om vooruit te kijken naar de rest van het schooljaar. Het is een tijd om plannen te maken, 
          achterstanden in te halen en zich mentaal voor te bereiden op de komende maanden.
        </p>
        <p>
          Al met al biedt de Voorjaarsvakantie een waardevolle onderbreking in het schooljaar, een gelegenheid om te reizen en te ontspannen, 
          en een kans om nieuwe ervaringen op te doen. Of je nu kiest voor verre reizen, lokale avonturen of rustige dagen thuis, 
          de Voorjaarsvakantie biedt voor ieder wat wils. Tevens wordt in het zuiden van het land in deze vakantie carnaval gevierd.
        </p>
        <h2>Wat is Carnaval?</h2>
        <p>
          Carnaval is een jaarlijks feest dat wordt gevierd in de dagen voorafgaand aan Aswoensdag, het begin van de vastentijd in het christendom. 
          Het is een tijd van feest, verkleedpartijen en optochten, en wordt vooral groots gevierd in het zuiden van Nederland, met name in Limburg en Noord-Brabant.
        </p>

        <img src={`${process.env.PUBLIC_URL}/images/voorjaarsvakantie-carnaval.jpg`} alt="voorjaarsvakantie carnaval" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px',display: 'block', margin: '0 auto'  }} />  
        <h3>Geschiedenis en Betekenis</h3>
        <p>
          Carnaval heeft zijn wortels in zowel heidense als christelijke tradities. Het oorspronkelijke doel was om de winter te verdrijven en de komst van de lente te vieren. 
          In de christelijke traditie markeert het de laatste kans om feest te vieren en uitgebreid te eten voordat de periode van vasten begint.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          Tijdens Carnaval kleden mensen zich in kleurrijke en vaak humoristische kostuums en maskers. Er worden tal van evenementen georganiseerd, 
          waaronder optochten met praalwagens, muziek en dans. Bekende carnavalssteden zoals Maastricht, Den Bosch en Tilburg trekken duizenden feestvierders.
        </p>
        <h3>De Elfde van de Elfde</h3>
        <p>
          De voorbereidingen voor Carnaval beginnen officieel op de elfde van november (de elfde van de elfde), een symbolisch getal dat wordt geassocieerd met gekte en dwaze streken. 
          Op deze dag worden de prinsen Carnaval gekozen en beginnen de carnavalsverenigingen met de voorbereidingen.
        </p>
        <h3>Maaltijden en Drankjes</h3>
        <p>
          Tijdens Carnaval zijn er verschillende traditionele gerechten en drankjes die populair zijn. Denk aan worstenbroodjes, bier en andere lokale specialiteiten. 
          Het is een tijd om te genieten van eten en drinken in goed gezelschap.
        </p>
        <h3>Carnavalsvakantie</h3>
        <p>
          In sommige delen van Nederland krijgen scholen een week vrij voor de Carnavalsvakantie. Dit geeft gezinnen de mogelijkheid om samen te vieren en deel te nemen aan de festiviteiten. 
          Voor veel mensen is het een gelegenheid om even te ontsnappen aan de dagelijkse routine en plezier te maken.
        </p>
        <p>
          Al met al is Carnaval een levendige en kleurrijke viering die diep geworteld is in de Nederlandse cultuur. Of je nu deelneemt aan de optochten, verkleedpartijen of gewoon geniet van de feestelijke sfeer, 
          Carnaval biedt voor iedereen een kans om samen te komen en het leven te vieren.
        </p>
      </div>
    </div>
  );
}

export default Voorjaarsvakantie;
