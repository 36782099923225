import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagWetenschap({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over de Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling en de belangrijke rol van wetenschap in duurzame ontwikkeling." />
  <meta name="keywords" content="wetenschap, duurzame ontwikkeling, vrede, innovatie, Internationale Dag van de Wetenschap" />
  <meta property="og:title" content="Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling" />
  <meta property="og:description" content="Leer over de impact van wetenschap op vrede en ontwikkeling wereldwijd." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20de%20wetenschap%20voor%20vrede%20en%20ontwikkeling" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="dag-wetenschap-info">
        <h1>Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling</h1>
        <p>
          De Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling, gevierd op 10 november, is een dag die wereldwijd wordt erkend om het belang van wetenschap en innovatie 
          voor duurzame ontwikkeling te benadrukken. Deze dag onderstreept de rol van wetenschap in het bevorderen van vreedzame samenlevingen en het aanpakken van mondiale uitdagingen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Rol van Wetenschap in de Samenleving</h3>
        <p>
          Wetenschap speelt een cruciale rol in het verbeteren van de levenskwaliteit en het bevorderen van technologische vooruitgang. 
          Het stimuleert innovatie en draagt bij aan oplossingen voor problemen zoals klimaatverandering, gezondheid en energievoorziening.
        </p>
        <h3>Waarom Wetenschap voor Vrede en Ontwikkeling?</h3>
        <p>
          De dag benadrukt hoe wetenschap kan bijdragen aan het opbouwen van vreedzame samenlevingen door het bevorderen van dialoog en samenwerking tussen landen. 
          Innovatie in wetenschap en technologie is essentieel voor duurzame economische groei en maatschappelijke ontwikkeling.
        </p>
        <h3>Activiteiten en Evenementen</h3>
        <p>
          Op deze dag worden wereldwijd evenementen en conferenties georganiseerd om de rol van wetenschap in ontwikkeling en vrede te vieren. 
          Er zijn workshops, lezingen en discussies die gericht zijn op het vergroten van het bewustzijn over de impact van wetenschap op ons dagelijks leven.
        </p>
        <p>
          Laten we deze dag benutten om onze waardering uit te spreken voor wetenschappers en innovators die onvermoeibaar werken aan het verbeteren van onze wereld.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagWetenschap;