import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function WereldWaterdag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Wereld Waterdag | Vrije Dagen NL</title>
  <meta name="description" content="Leer meer over Wereld Waterdag en de uitdagingen rondom waterbeheer. Ontdek hoe je kunt bijdragen aan schoon water wereldwijd." />
  <meta name="keywords" content="Wereld Waterdag, waterbeheer, schoon water, milieu, duurzame ontwikkeling" />
  <meta property="og:title" content="Wereld Waterdag | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek de betekenis van Wereld Waterdag en hoe jij kunt bijdragen aan een betere toegang tot schoon water." />
  <meta property="og:url" content="https://vrije-dagen.nl/wereld%20waterdag" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="wereldwaterdag-info">
        <h1>Wat is Wereld Waterdag?</h1>
        <p>
          Wereld Waterdag wordt elk jaar op 22 maart gevierd en is bedoeld om wereldwijd aandacht te vragen voor watergerelateerde uitdagingen en kwesties. 
          Het doel van deze dag is om bewustzijn te creëren rondom de toegang tot schoon drinkwater en het behoud van waterbronnen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Geschiedenis van Wereld Waterdag</h3>
        <p>
          Wereld Waterdag werd in 1993 door de Verenigde Naties in het leven geroepen om het belang van water en de noodzaak van duurzaam waterbeheer onder de aandacht te brengen. 
          Sindsdien wordt deze dag wereldwijd gevierd met evenementen en initiatieven gericht op watergerelateerde problemen.
        </p>
        <h3>Het Belang van Schoon Water</h3>
        <p>
          Schoon water is een fundamenteel mensenrecht en essentieel voor het welzijn van zowel mensen als ecosystemen. 
          Wereldwijd hebben miljoenen mensen nog steeds geen toegang tot schoon drinkwater, wat leidt tot gezondheidsproblemen, armoede en conflicten.
        </p>
        <h3>Hoe Je Kunt Bijdragen</h3>
        <p>
          Er zijn veel manieren waarop je kunt bijdragen aan de doelstellingen van Wereld Waterdag. Je kunt bewust water besparen in je dagelijks leven, 
          deelnemen aan lokale schoonmaakacties of doneren aan organisaties die werken aan watervoorziening en -beheer in ontwikkelingslanden.
        </p>
        <p>
          Wereld Waterdag herinnert ons eraan dat water een kostbare hulpbron is die we moeten beschermen en koesteren. 
          Door bewustzijn te creëren en actie te ondernemen, kunnen we een verschil maken in de levens van miljoenen mensen wereldwijd.
        </p>
      </div>
    </div>
  );
}

export default WereldWaterdag;