import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Header from './components/Header';
import Footer from './components/Footer';
import HeroBanner from './components/HeroBanner';
import AboutUs from './components/AboutUs'; 
import NotFound from './components/NotFound';

import SchoolHolidays from './components/SchoolHolidays/SchoolHolidays';
import Herfstvakantie from './components/SchoolHolidays/Herfstvakantie';
import Kerstvakantie from './components/SchoolHolidays/Kerstvakantie';
import Voorjaarsvakantie from './components/SchoolHolidays/Voorjaarsvakantie';
import Meivakantie from './components/SchoolHolidays/Meivakantie';
import Zomervakantie from './components/SchoolHolidays/Zomervakantie';
import Bouwvak from './components/SchoolHolidays/Bouwvak';
import Regio from './components/SchoolHolidays/Regio';

import Holidays from './components/Holidays/Holidays';
import Bevrijdingsdag from './components/Holidays/Bevrijdingsdag';
import Goedevrijdag from './components/Holidays/Goedevrijdag';
import Koningsdag from './components/Holidays/Koningsdag';
import Oudjaarsdag from './components/Holidays/Oudjaarsdag';
import Nieuwjaarsdag from './components/Holidays/Nieuwjaarsdag';
import EerstePinksterdag from './components/Holidays/EerstePinksterdag';
import TweedePinksterdag from './components/Holidays/TweedePinksterdag';
import EersteKerstdag from './components/Holidays/EersteKerstdag';
import TweedeKerstdag from './components/Holidays/TweedeKerstdag';
import Hemelvaart from './components/Holidays/Hemelvaart';
import EerstePaasdag from './components/Holidays/EerstePaasdag';
import TweedePaasdag from './components/Holidays/TweedePaasdag';
import Suikerfeest from './components/Holidays/Suikerfeest';

import SpecialeDagen from './components/SpecialeDagen/SpecialeDagen';
import BlackFriday from './components/SpecialeDagen/BlackFriday';
import CyberMonday from './components/SpecialeDagen/CyberMonday';
import SinglesDay from './components/SpecialeDagen/SinglesDay';
import Chanoeka from './components/SpecialeDagen/Chanoeka';
import InternationaleVrouwendag from './components/SpecialeDagen/InternationaleVrouwendag';
import Wereldmilieudag from './components/SpecialeDagen/Wereldmilieudag';
import NationaleVrijwilligersdag from './components/SpecialeDagen/NationaleVrijwilligersdag';
import Moederdag from './components/SpecialeDagen/Moederdag';
import Vaderdag from './components/SpecialeDagen/Vaderdag';
import Secretaressedag from './components/SpecialeDagen/Secretaressedag';
import Grootouderdag from './components/SpecialeDagen/Grootouderdag';
import DagVanDeArbeid from './components/SpecialeDagen/DagVanDeArbeid';
import Valentijnsdag from './components/SpecialeDagen/Valentijnsdag';
import WereldWaterdag from './components/SpecialeDagen/WereldWaterdag';
import EarthDay from './components/SpecialeDagen/EarthDay';
import InternationaleDagVanHetGeluk from './components/SpecialeDagen/InternationaleDagVanHetGeluk';
import Dierendag from './components/SpecialeDagen/Dierendag';
import Halloween from './components/SpecialeDagen/Halloween';
import Sinterklaasavond from './components/SpecialeDagen/Sinterklaasavond';
import InternationaleMannendag from './components/SpecialeDagen/InternationaleMannendag';
import InternationaleDagTegenRacisme from './components/SpecialeDagen/InternationaleDagTegenRacisme';
import WereldDiabetesDag from './components/SpecialeDagen/WereldDiabetesDag';
import InternationaleDagVanHetKind from './components/SpecialeDagen/InternationaleDagVanHetKind';
import Wereldgezondheidsdag from './components/SpecialeDagen/Wereldgezondheidsdag';
import InternationaleDagVanDeVriendschap from './components/SpecialeDagen/InternationaleDagVanDeVriendschap';
import WereldToerismedag from './components/SpecialeDagen/WereldToerismedag';
import InternationaleJazzdag from './components/SpecialeDagen/InternationaleJazzdag';
import Wereldboekendag from './components/SpecialeDagen/Wereldboekendag';
import InternationaleDagWetenschap from './components/SpecialeDagen/InternationaleDagWetenschap';
import WereldVoedseldag from './components/SpecialeDagen/WereldVoedseldag';
import WereldEmojiDag from './components/SpecialeDagen/WereldEmojiDag';
import InternationaleDagVanDeYoga from './components/SpecialeDagen/InternationaleDagVanDeYoga';
import PiDag from './components/SpecialeDagen/PiDag';
import DagVanDeRuimtevaart from './components/SpecialeDagen/DagVanDeRuimtevaart';
import InternationaleDagVanDeVrede from './components/SpecialeDagen/InternationaleDagVanDeVrede';


import PaklijstZomervakantie from './components/Paklijsten/PaklijstZomervakantie';
import PaklijstWintersport from './components/Paklijsten/PaklijstWintersport';
import CityTripPaklijst from './components/Paklijsten/CitytripPaklijst';
import WandelvakantiePaklijst from './components/Paklijsten/WandelvakantiePaklijst';
import CruisevakantiePaklijst from './components/Paklijsten/CruisevakantiePaklijst';

import Disclaimer from './components/Disclaimer';
import CookieConsent from './components/CookieConsent';
/* import VakantiePlanner from './components/VakantiePlanner';  */

import Blog from './components/Blog';
import BlogPost from './components/BlogPost';

import './App.css';

// Initialize Google Analytics
ReactGA.initialize('G-KL4196D1LS'); // Replace with your actual Measurement ID

// Custom hook to track page views
const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

function App() {

  const currentYear = new Date().getFullYear().toString();

  // Schoolvakanties
  const schoolvakantieFilter = { vakantie: '', year: currentYear };
  const zomervakantieFilters = { vakantie: 'Zomervakantie', year: '' };
  const voorjaarsvakantieFilters = { vakantie: 'Voorjaarsvakantie', year: '' };
  const meivakantieFilters = { vakantie: 'Meivakantie', year: '' };
  const kerstvakantieFilters = { vakantie: 'Kerstvakantie', year: '' };
  const bouwvakFilters = { vakantie: 'Bouwvak', year: '' };
  const herfstvakantieFilters = { vakantie: 'Herfstvakantie', year: '' };

  //Specialedagenß
  const specialeDagenFilter = { search: '', year: currentYear };
  const blackFridayFilters = { search: 'Black friday', year: '' };
  const cyberMondayFilters = { search: 'Cyber monday', year: '' };
  const singlesDayFilter = { search: 'Singles day', year: '' };
  const chanoekaFilter = { search: 'Chanoeka', year: '' };
  const internationaleVrouwendagFilter = { search: 'Internationale vrouwendag', year: '' };
  const wereldmilieudagFilter = { search: 'wereldmilieudag', year: '' };
  const nationaleVrijwilligersdagFilter = { search: 'Nationale Vrijwilligersdag', year: '' };
  const moederdagFilter = { search: 'Moederdag', year: '' };
  const vaderdagFilter = { search: 'Vaderdag', year: '' };
  const secretaressedagFilter = { search: 'Secretaressedag', year: '' };
  const grootouderdagFilter = { search: 'Grootouderdag', year: '' };
  const dagVanDeArbeidFilter = { search: 'Dag van de arbeid', year: '' };
  const valentijnsdagFilter = { search: 'Valentijnsdag', year: '' };
  const wereldWaterdagFilter = { search: 'Wereld waterdag', year: '' };
  const earthDayFilter = { search: 'Earth Day', year: '' };
  const internationaleDagVanHetGelukFilter = { search: 'Internationale Dag van het Geluk', year: '' };
  const dierendagFilter = { search: 'Dierendag', year: '' };
  const halloweenFilter = { search: 'Halloween', year: '' };
  const sinterklaasavondFilter = { search: 'Sinterklaasavond', year: '' };
  const internationaleMannendagFilter = { search: 'Internationale Mannendag', year: '' };
  const internationaleDagTegenRacismeFilter = { search: 'Internationale Dag tegen Racisme', year: '' };
  const wereldDiabetesDagFilter = { search: 'Wereld Diabetes Dag', year: '' };
  const internationaleDagVanHetKindFilter = { search: 'Internationale Dag van het Kind', year: '' };
  const wereldgezondheidsdagFilter = { search: 'Wereldgezondheidsdag', year: '' };
  const internationaleDagVanDeVriendschapFilter = { search: 'Internationale Dag van de Vriendschap', year: '' };
  const wereldToerismedagFilter = { search: 'Wereld Toerismedag', year: '' };
  const internationaleJazzdagFilter = { search: 'Internationale Jazzdag', year: '' };
  const wereldboekendagFilter = { search: 'Wereldboekendag', year: '' };
  const internationaleDagWetenschapFilter = { search: 'Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling', year: '' };
  const wereldVoedseldagFilter = { search: 'Wereld Voedseldag', year: '' };
  const wereldEmojiDagFilter = { search: 'Wereld Emoji Dag', year: '' };
  const internationaleDagVanDeYogaFilter = { search: 'Internationale Dag van de Yoga', year: '' };
  const piDagFilter = { search: 'Pi-Dag', year: '' };
  const dagVanDeRuimtevaartFilter = { search: 'Dag van de Ruimtevaart', year: '' };
  const internationaleDagVanDeVredeFilter = { search: 'Internationale Dag van de Vrede', year: '' };

  // Feestdagen
  const feestdagenFilters = { search: '', year: currentYear };
  const koningsdagFilters = { search: 'Koningsdag', year: '' };
  const nieuwjaarsdagFilter = { search: 'Nieuwjaarsdag', year: '' };
  const oudjaarsdagFilter = { search: 'Oudjaarsdag', year: '' };
  const eerstePinksterdagFilter = { search: '1ste Pinksterdag', year: '' };
  const tweedePinksterdagFilter = { search: '2de Pinksterdag', year: '' };
  const goedevrijdagFilter = { search: 'Goede Vrijdag', year: '' };
  const bevrijdingsdagFilter = { search: 'Bevrijdingsdag', year: '' };
  const eerstePaasdagFilter = { search: '1ste Paasdag', year: '' };
  const tweedePaasdagFilter = { search: '2de Paasdag', year: '' };
  const eersteKerstdagFilter = { search: '1ste Kerstdag', year: '' };
  const tweedeKerstdagFilter = { search: '2de Kerstdag', year: '' };
  const hemelvaartFilter = { search: 'Hemelvaart', year: '' };
  const suikerfeestFilter = { search: 'Suikerfeest', year: '' };

  // Separate component to handle page tracking within Router
const PageTrackingWrapper = () => {
  usePageTracking(); // Now this is inside Router
  return null; // Doesn't need to render anything
};


  return (
    <Router>
       <PageTrackingWrapper /> {/* This ensures tracking happens within the Router */}
      <Header />
      <HeroBanner />
      <main className="main-content">
        <div className="content-container">
          <Routes>
            <Route path="/" element={<SchoolHolidays defaultFilters={schoolvakantieFilter} />} />

            <Route path="/herfstvakantie" element={<Herfstvakantie defaultFilters={herfstvakantieFilters} />} />
            <Route path="/bouwvak" element={<Bouwvak defaultFilters={bouwvakFilters} />} />
            <Route path="/kerstvakantie" element={<Kerstvakantie defaultFilters={kerstvakantieFilters} />} />
            <Route path="/voorjaarsvakantie" element={<Voorjaarsvakantie defaultFilters={voorjaarsvakantieFilters} />} />
            <Route path="/meivakantie" element={<Meivakantie defaultFilters={meivakantieFilters} />} />
            <Route path="/zomervakantie" element={<Zomervakantie defaultFilters={zomervakantieFilters} />} />
            <Route path="/schoolvakanties" element={<SchoolHolidays defaultFilters={schoolvakantieFilter} />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/paklijst-zomervakantie" element={<PaklijstZomervakantie />} />
            <Route path="/paklijst-wintersport" element={<PaklijstWintersport />} />
            <Route path="/paklijst-citytrip" element={<CityTripPaklijst />} />    
            <Route path="/paklijst-wandelvakantie" element={<WandelvakantiePaklijst />} />      
            <Route path="/paklijst-cruisevakantie" element={<CruisevakantiePaklijst />} />                      
            <Route path="/regios" element={<Regio />} />

            <Route path="/feestdagen" element={<Holidays defaultFilters={feestdagenFilters} />} />
            <Route path="/koningsdag" element={<Koningsdag defaultFilters={koningsdagFilters} />} />
            <Route path="/nieuwjaarsdag" element={<Nieuwjaarsdag defaultFilters={nieuwjaarsdagFilter} />} />
            <Route path="/oudjaarsdag" element={<Oudjaarsdag defaultFilters={oudjaarsdagFilter} />} />
            <Route path="/1ste pinksterdag" element={<EerstePinksterdag defaultFilters={eerstePinksterdagFilter} />} />
            <Route path="/2de pinksterdag" element={<TweedePinksterdag defaultFilters={tweedePinksterdagFilter} />} />
            <Route path="/bevrijdingsdag" element={<Bevrijdingsdag defaultFilters={bevrijdingsdagFilter} />} />
            <Route path="/goede vrijdag" element={<Goedevrijdag defaultFilters={goedevrijdagFilter} />} />
            <Route path="/1ste paasdag" element={<EerstePaasdag defaultFilters={eerstePaasdagFilter} />} />
            <Route path="/2de paasdag" element={<TweedePaasdag defaultFilters={tweedePaasdagFilter} />} />
            <Route path="/hemelvaart" element={<Hemelvaart defaultFilters={hemelvaartFilter} />} />
            <Route path="/suikerfeest" element={<Suikerfeest defaultFilters={suikerfeestFilter} />} />
            <Route path="/1ste kerstdag" element={<EersteKerstdag defaultFilters={eersteKerstdagFilter} />} />
            <Route path="/2de kerstdag" element={<TweedeKerstdag defaultFilters={tweedeKerstdagFilter} />} />

            <Route path="/specialedagen" element={<SpecialeDagen defaultFilters={specialeDagenFilter} />} />  
            <Route path="/black friday" element={<BlackFriday defaultFilters={blackFridayFilters} />} />        
            <Route path="/cyber monday" element={<CyberMonday defaultFilters={cyberMondayFilters} />} />    
            <Route path="/singles day" element={<SinglesDay defaultFilters={singlesDayFilter} />} /> 
            <Route path="/chanoeka" element={<Chanoeka defaultFilters={chanoekaFilter} />} />   
            <Route path="/internationale vrouwendag" element={<InternationaleVrouwendag defaultFilters={internationaleVrouwendagFilter} />} />  
            <Route path="/wereldmilieudag" element={<Wereldmilieudag defaultFilters={wereldmilieudagFilter} />} />  
            <Route path="/nationale Vrijwilligersdag" element={<NationaleVrijwilligersdag defaultFilters={nationaleVrijwilligersdagFilter} />} />    
            <Route path="/moederdag" element={<Moederdag defaultFilters={moederdagFilter} />} />   
            <Route path="/vaderdag" element={<Vaderdag defaultFilters={vaderdagFilter} />} /> 
            <Route path="/secretaressedag" element={<Secretaressedag defaultFilters={secretaressedagFilter} />} /> 
            <Route path="/grootouderdag" element={<Grootouderdag defaultFilters={grootouderdagFilter} />} /> 
            <Route path="/dag van de arbeid" element={<DagVanDeArbeid defaultFilters={dagVanDeArbeidFilter} />} /> 
            <Route path="/valentijnsdag" element={<Valentijnsdag defaultFilters={valentijnsdagFilter} />} /> 
            <Route path="/Wereld waterdag" element={<WereldWaterdag defaultFilters={wereldWaterdagFilter} />} /> 
            <Route path="/Earth Day" element={<EarthDay defaultFilters={earthDayFilter} />} /> 
            <Route path="/Internationale Dag van het Geluk" element={<InternationaleDagVanHetGeluk defaultFilters={internationaleDagVanHetGelukFilter} />} /> 
            <Route path="/dierendag" element={<Dierendag defaultFilters={dierendagFilter} />} /> 
            <Route path="/halloween" element={<Halloween defaultFilters={halloweenFilter} />} /> 
            <Route path="/sinterklaasavond" element={<Sinterklaasavond defaultFilters={sinterklaasavondFilter} />} /> 
            <Route path="/internationale Mannendag" element={<InternationaleMannendag defaultFilters={internationaleMannendagFilter} />} /> 
            <Route path="/Internationale Dag tegen Racisme" element={<InternationaleDagTegenRacisme defaultFilters={internationaleDagTegenRacismeFilter} />} /> 
            <Route path="/Wereld Diabetes Dag" element={<WereldDiabetesDag defaultFilters={wereldDiabetesDagFilter} />} /> 
            <Route path="/Internationale Dag van het Kind" element={<InternationaleDagVanHetKind defaultFilters={internationaleDagVanHetKindFilter} />} /> 
            <Route path="/Wereldgezondheidsdag" element={<Wereldgezondheidsdag defaultFilters={wereldgezondheidsdagFilter} />} /> 
            <Route path="/Internationale Dag van de Vriendschap" element={<InternationaleDagVanDeVriendschap defaultFilters={internationaleDagVanDeVriendschapFilter} />} /> 
            <Route path="/Wereld Toerismedag" element={<WereldToerismedag defaultFilters={wereldToerismedagFilter} />} />
            <Route path="/Internationale Jazzdag" element={<InternationaleJazzdag defaultFilters={internationaleJazzdagFilter} />} />
            <Route path="/Wereldboekendag" element={<Wereldboekendag defaultFilters={wereldboekendagFilter} />} />
            <Route path="/Internationale Dag van de Wetenschap voor Vrede en Ontwikkeling" element={<InternationaleDagWetenschap defaultFilters={internationaleDagWetenschapFilter} />} />
            <Route path="/Wereld Voedseldag" element={<WereldVoedseldag defaultFilters={wereldVoedseldagFilter} />} />
            <Route path="/Wereld Emoji Dag" element={<WereldEmojiDag defaultFilters={wereldEmojiDagFilter} />} />
            <Route path="/Internationale Dag van de Yoga" element={<InternationaleDagVanDeYoga defaultFilters={internationaleDagVanDeYogaFilter} />} />
            <Route path="/Pi-Dag" element={<PiDag defaultFilters={piDagFilter} />} />
            <Route path="/Dag van de Ruimtevaart" element={<DagVanDeRuimtevaart defaultFilters={dagVanDeRuimtevaartFilter} />} />
            <Route path="/Internationale Dag van de Vrede" element={<InternationaleDagVanDeVrede defaultFilters={internationaleDagVanDeVredeFilter} />} />           

            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/about-us" element={<AboutUs />} />

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />

          </Routes>
        </div>
      </main>
      <CookieConsent />
      <Footer />
    </Router>
  );
}


export default App;