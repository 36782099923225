import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function Goedevrijdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
<Helmet>
  <title>Goede Vrijdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Goede Vrijdag in Nederland, inclusief de religieuze en culturele betekenis." />
  <meta name="keywords" content="Goede Vrijdag, feestdagen, Nederland, religie, christelijke feestdag" />
  <meta property="og:title" content="Goede Vrijdag in Nederland" />
  <meta property="og:description" content="Leer meer over de betekenis en tradities van Goede Vrijdag in Nederland." />
  <meta property="og:url" content="https://vrije-dagen.nl/goede%20vrijdag" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="goede-vrijdag-info">
        <h1>Wat is Goede Vrijdag?</h1>
        <p>
          Goede Vrijdag, de vrijdag voor Pasen, is een belangrijke christelijke feestdag die de kruisiging van Jezus Christus herdenkt. 
          Het is een dag van rouw en reflectie, maar ook van hoop op de opstanding die met Pasen wordt gevierd.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Goede Vrijdag markeert het lijden en sterven van Jezus Christus aan het kruis op Golgotha. Volgens de christelijke traditie werd Jezus gekruisigd, stierf hij en werd hij begraven. 
          Deze gebeurtenissen worden vaak herdacht met speciale kerkdiensten waarin het lijdensverhaal wordt voorgelezen en er momenten van stilte en gebed zijn.
        </p>
        <h3>Vieringen en Tradities</h3>
        <p>
          In Nederland worden er op Goede Vrijdag in veel kerken diensten gehouden waarin het lijdensverhaal van Christus centraal staat. 
          De diensten zijn vaak sober en ingetogen, met veel aandacht voor de Bijbellezingen, gebeden en liederen die het lijden van Jezus herdenken.
        </p>
        <p>
          Een gebruikelijke traditie is de kruisweg, een serie van veertien afbeeldingen of staties die de gebeurtenissen van Jezus' veroordeling tot zijn begrafenis weergeven. 
          Gelovigen lopen langs deze staties, mediteren en bidden bij elke scène.
        </p>
        <h3>Stilte en Bezinning</h3>
        <p>
          Goede Vrijdag is een dag van stilte en bezinning. Veel mensen kiezen ervoor om deze dag op een ingetogen manier door te brengen, zonder feestelijkheden. 
          Het is een tijd om stil te staan bij het lijden van Jezus en de betekenis van zijn offer voor de mensheid.
        </p>
        <h3>Culturele Aspecten</h3>
        <p>
          Hoewel Goede Vrijdag een religieuze feestdag is, zijn er ook culturele aspecten aan verbonden. In sommige delen van Nederland worden er passiespelen opgevoerd, 
          waarin de laatste uren van Jezus' leven worden uitgebeeld. Deze spelen trekken vaak veel bezoekers en bieden een diepgaande ervaring van de gebeurtenissen van Goede Vrijdag.
        </p>
        <h3>De Weg naar Pasen</h3>
        <p>
          Goede Vrijdag maakt deel uit van de Heilige Week, de laatste week van de vastentijd die begint met Palmzondag en culmineert in Pasen. 
          Het is een belangrijke dag in de voorbereiding op de vreugde van de opstanding die met Pasen wordt gevierd. 
          De soberheid van Goede Vrijdag staat in schril contrast met de feestelijke sfeer van Pasen, wat de betekenis van beide dagen versterkt.
        </p>
        <h3>Een Dag van Nationale Reflectie</h3>
        <p>
          In Nederland is Goede Vrijdag een officiële feestdag, maar geen verplichte vrije dag. Dit betekent dat veel mensen werken, maar er zijn ook velen die de dag gebruiken voor reflectie en religieuze viering. 
          Het is een dag die uitnodigt tot bezinning over lijden, offer en verlossing, en die ons voorbereidt op de hoop en vreugde van Pasen.
        </p>
      </div>
    </div>
  );
}

export default Goedevrijdag;
