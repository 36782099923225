import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleVrouwendag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays;  // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Vrouwendag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Internationale Vrouwendag en de strijd voor vrouwenrechten en gendergelijkheid." />
  <meta name="keywords" content="Internationale Vrouwendag, vrouwenrechten, gendergelijkheid, 8 maart" />
  <meta property="og:title" content="Internationale Vrouwendag" />
  <meta property="og:description" content="Lees meer over de betekenis en viering van Internationale Vrouwendag wereldwijd." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20vrouwendag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="vrouwendag-info">
        <h1>Wat is Internationale Vrouwendag?</h1>
        <p>
          Internationale Vrouwendag wordt wereldwijd gevierd op 8 maart en is gewijd aan de viering van vrouwenrechten en gendergelijkheid. 
          Het is een dag waarop de sociale, economische, culturele en politieke prestaties van vrouwen worden erkend en gevierd.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Oorsprong van Internationale Vrouwendag</h3>
        <p>
          De oorsprong van Internationale Vrouwendag gaat terug naar het begin van de 20e eeuw, toen vrouwen over de hele wereld begonnen te strijden voor gelijke rechten, 
          betere arbeidsomstandigheden en het recht om te stemmen. De eerste officiële viering vond plaats in 1911.
        </p>
        <h3>Thema's en Campagnes</h3>
        <p>
          Elk jaar heeft Internationale Vrouwendag een specifiek thema dat actuele kwesties belicht met betrekking tot gendergelijkheid, vrouwenrechten en empowerment. 
          Deze thema's variëren van economische inclusie en onderwijs voor meisjes tot het aanpakken van gendergerelateerd geweld.
        </p>
        <h3>Hoe Internationale Vrouwendag wereldwijd wordt gevierd</h3>
        <p>
          Wereldwijd worden er op 8 maart evenementen, demonstraties, conferenties en optochten georganiseerd. Bedrijven, gemeenschappen en individuen komen samen om 
          hun steun te tonen voor gendergelijkheid en om de prestaties van vrouwen te vieren.
        </p>
        <h3>Waarom Internationale Vrouwendag belangrijk is</h3>
        <p>
          Internationale Vrouwendag benadrukt de voortdurende strijd voor gendergelijkheid en inspireert tot actie. 
          Het is een dag om stil te staan bij de vooruitgang die is geboekt, maar ook om de uitdagingen te erkennen die nog steeds bestaan.
        </p>
        <p>
          Of het nu gaat om het verbeteren van arbeidsrechten, toegang tot onderwijs of de strijd tegen discriminatie, Internationale Vrouwendag is een belangrijk moment om 
          verandering te bevorderen en een inclusieve wereld te creëren waarin vrouwen gelijk worden behandeld.
        </p>
      </div>
    </div>
  );
}

export default InternationaleVrouwendag;