import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagTegenRacisme({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag tegen Racisme | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over de Internationale Dag tegen Racisme en de strijd tegen discriminatie." />
  <meta name="keywords" content="Internationale Dag tegen Racisme, racisme, discriminatie, gelijkheid, bewustwording" />
  <meta property="og:title" content="Internationale Dag tegen Racisme" />
  <meta property="og:description" content="Leer meer over de geschiedenis, activiteiten en stappen tegen racisme op de Internationale Dag tegen Racisme." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20tegen%20racisme" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-dag-tegen-racisme-info">
        <h1>Wat is de Internationale Dag tegen Racisme?</h1>
        <p>
          De Internationale Dag tegen Racisme, gevierd op 21 maart, is een dag om bewustzijn te creëren rondom racisme en discriminatie. 
          Het doel is om solidariteit te bevorderen en stappen te ondernemen tegen elke vorm van raciale onrechtvaardigheid.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Bewustwording en Educatie</h3>
        <p>
          Deze dag richt zich op het vergroten van het bewustzijn over de impact van racisme op individuen en samenlevingen. 
          Het benadrukt de noodzaak om educatie en bewustwording te bevorderen als middel om racisme en discriminatie uit te bannen.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Wereldwijd worden er op de Internationale Dag tegen Racisme verschillende evenementen georganiseerd, zoals lezingen, debatten, filmscreenings en workshops. 
          Deze activiteiten zijn bedoeld om mensen te informeren, te inspireren en te mobiliseren om actie te ondernemen tegen racisme.
        </p>
        <h3>Stappen Tegen Racisme</h3>
        <p>
          Op deze dag wordt iedereen aangemoedigd om hun steentje bij te dragen aan de strijd tegen racisme. Dit kan door middel van persoonlijke acties, 
          zoals het delen van informatie op sociale media, of door deel te nemen aan protesten en gemeenschapsevenementen.
        </p>
        <h3>De Rol van de Samenleving</h3>
        <p>
          De Internationale Dag tegen Racisme onderstreept het belang van samenwerking en een gezamenlijke inspanning om een rechtvaardige en inclusieve samenleving te creëren. 
          Het vraagt mensen van alle rassen en etnische groepen om solidair te zijn in de strijd tegen raciale vooroordelen en discriminatie.
        </p>
        <h3>Een Toekomst zonder Discriminatie</h3>
        <p>
          Het uiteindelijke doel van de Internationale Dag tegen Racisme is om een wereld te creëren waarin iedereen gelijk wordt behandeld, ongeacht hun ras of etnische achtergrond. 
          Het is een dag om na te denken over de vooruitgang die we hebben geboekt en de stappen die we nog moeten zetten om raciale gelijkheid te bereiken.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagTegenRacisme;