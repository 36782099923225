import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function EarthDay({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Earth Day | Vrije Dagen NL</title>
  <meta name="description" content="Leer meer over Earth Day en het belang van milieubescherming." />
  <meta name="keywords" content="Earth Day, milieubescherming, duurzaamheid, natuur, klimaat" />
  <meta property="og:title" content="Earth Day | Vrije Dagen NL" />
  <meta property="og:description" content="Ontdek de geschiedenis van Earth Day en hoe je kunt bijdragen aan een gezondere planeet." />
  <meta property="og:url" content="https://vrije-dagen.nl/earth%20day" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="earth-day-info">
        <h1>Wat is Earth Day?</h1>
        <p>
          Earth Day, gevierd op 22 april, is een wereldwijde dag gewijd aan het bewustzijn van milieuproblemen en het bevorderen van milieubescherming. 
          Het doel is om mensen over de hele wereld te inspireren om actie te ondernemen voor een gezondere planeet.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Geschiedenis van Earth Day</h3>
        <p>
          Earth Day werd voor het eerst gevierd in 1970 in de Verenigde Staten en is sindsdien uitgegroeid tot een wereldwijde beweging. 
          Het initiatief bracht miljoenen mensen samen om te protesteren tegen milieuvervuiling en leidde tot belangrijke veranderingen in milieuwetgeving.
        </p>
        <h3>Het Belang van Milieubescherming</h3>
        <p>
          De planeet staat voor vele uitdagingen, waaronder klimaatverandering, vervuiling, ontbossing en verlies van biodiversiteit. 
          Earth Day benadrukt het belang van collectieve inspanningen om deze problemen aan te pakken en duurzame praktijken te bevorderen.
        </p>
        <h3>Hoe Je Kunt Bijdragen</h3>
        <p>
          Iedereen kan bijdragen aan de doelstellingen van Earth Day door kleine veranderingen in hun dagelijks leven door te voeren, zoals energie besparen, afval verminderen, 
          deelnemen aan milieuprojecten en het steunen van initiatieven voor natuurbescherming.
        </p>
        <p>
          Earth Day herinnert ons eraan dat we allemaal een verantwoordelijkheid hebben om voor onze planeet te zorgen. 
          Door bewustzijn te vergroten en actie te ondernemen, kunnen we een positieve impact maken op de gezondheid van onze aarde voor toekomstige generaties.
        </p>
      </div>
    </div>
  );
}

export default EarthDay;