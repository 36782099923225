import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BlogTables.css';

function BlogPost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/blogPosts.json`)
      .then(response => {
        const post = response.data.posts.find(p => p.id === id);
        setPost(post);
      })
      .catch(error => console.error('Error fetching blog post:', error));
  }, [id]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="blog-post-container">
      <h1>{post.title}</h1>
      <p>{post.contentIntro}</p>
      <img src={`${process.env.PUBLIC_URL}${post.image}`} alt={post.title} className="blog-post-image" />
      <p>{post.contentMainSectionOne}</p>
      <p>{post.contentMainSectionTwo}</p>
      <p>{post.contentEnd}</p>
    </div>
  );
}

export default BlogPost;
