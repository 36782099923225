import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function WereldVoedseldag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Wereld Voedseldag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Wereld Voedseldag, de dag waarop wereldwijd aandacht wordt besteed aan voedselzekerheid en de strijd tegen honger." />
  <meta name="keywords" content="Wereld Voedseldag, voedselzekerheid, hongerbestrijding, duurzame voedselproductie" />
  <meta property="og:title" content="Wereld Voedseldag" />
  <meta property="og:description" content="Leer meer over de betekenis van Wereld Voedseldag en hoe we samen kunnen bijdragen aan voedselzekerheid en duurzaamheid." />
  <meta property="og:url" content="https://vrije-dagen.nl/wereld%20voedseldag" />
  <meta property="og:type" content="website" />
</Helmet>​⬤
      <div className="dag-voedseldag-info">
        <h1>Wereld Voedseldag</h1>
        <p>
          Wereld Voedseldag, gevierd op 16 oktober, is een belangrijke dag waarop wereldwijd aandacht wordt besteed aan voedselzekerheid en de strijd tegen honger. 
          Deze dag benadrukt het belang van toegang tot gezond en voedzaam voedsel voor iedereen, en spoort mensen aan om samen te werken aan het uitbannen van honger.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Het Belang van Wereld Voedseldag</h3>
        <p>
          Wereld Voedseldag is opgericht door de Voedsel- en Landbouworganisatie van de Verenigde Naties (FAO) om wereldwijd bewustzijn te creëren over de problematiek rondom voedselvoorziening. 
          Het is een kans om stil te staan bij de uitdagingen die voedselonzekerheid met zich meebrengt en te werken aan duurzame oplossingen.
        </p>
        <h3>Voedselzekerheid en Duurzaamheid</h3>
        <p>
          Voedselzekerheid is een groot probleem dat miljoenen mensen over de hele wereld raakt. Op deze dag worden er initiatieven en projecten gepromoot die gericht zijn op het verbeteren van landbouwpraktijken, 
          het verminderen van voedselverspilling en het ondersteunen van lokale voedselproductie.
        </p>
        <h3>Wat Kun Jij Doen?</h3>
        <p>
          Iedereen kan een bijdrage leveren door bewust te kiezen voor duurzame en lokale producten, minder voedsel te verspillen en zich in te zetten voor goede doelen die zich richten op voedselhulp. 
          Samen kunnen we de strijd tegen honger winnen en een duurzamere toekomst bouwen.
        </p>
      </div>
    </div>
  );
}

export default WereldVoedseldag;