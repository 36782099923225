import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HolidayFilter from './HolidayFilter';
import HolidayTable from './HolidayTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function TweedePaasdag({ defaultFilters, onFilterChange }) {
  const [holidays, setHolidays] = useState([]);
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/holidays.json`, { timeout: 5000 })
      .then(response => {
        const holidays = response.data.holidays;  // Extract holidays array
        setHolidays(holidays);
        filterHolidays(holidays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (holidays.length > 0) {
      filterHolidays(holidays, filters);
    }
  }, [filters, holidays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterHolidays = (holidays, filters) => {
    let updatedHolidays = holidays;

    if (!Array.isArray(updatedHolidays)) {
      console.error('Updated holidays is not an array:', updatedHolidays);
      return;
    }

    if (filters.search) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedHolidays = updatedHolidays.filter(holiday => {
        const holidayYear = holiday.date.split('-')[0];
        return holidayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.date.includes(`-${filters.month}-`)
      );
    }

    if (filters.officialOnly) {
      updatedHolidays = updatedHolidays.filter(holiday =>
        holiday.official === true
      );
    }

    setFilteredHolidays(updatedHolidays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
<Helmet>
  <title>Tweede Paasdag in Nederland | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Tweede Paasdag in Nederland en hoe deze feestdag wordt gevierd." />
  <meta name="keywords" content="Tweede Paasdag, Paasmaandag, feestdagen, Nederland, paasvieringen" />
  <meta property="og:title" content="Tweede Paasdag in Nederland" />
  <meta property="og:description" content="Leer meer over de tradities en activiteiten rond Tweede Paasdag in Nederland." />
  <meta property="og:url" content="https://vrije-dagen.nl/2de%20paasdag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="tweede-paasdag-info">
        <h1>Wat is Tweede Paasdag?</h1>
        <p>
          Tweede Paasdag, ook wel Paasmaandag genoemd, is de maandag na Pasen en een officiële feestdag in Nederland. Het biedt een verlenging van de paasvieringen 
          en geeft mensen de gelegenheid om nog een dag vrij te nemen om te genieten van familie, vrienden en verschillende activiteiten.
        </p>
        <h3>Hier vind je alle relevante data nog een keer op een rij:</h3>
        <HolidayFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <HolidayTable holidays={filteredHolidays} />
        <AdSenseAd />
        <h3>Religieuze Betekenis</h3>
        <p>
          Hoewel Tweede Paasdag minder religieuze betekenis heeft dan Eerste Paasdag, blijft het een onderdeel van het paasweekend. 
          Veel mensen gebruiken deze dag om de paasvieringen voort te zetten, vaak met een kerkdienst of een familiebrunch.
        </p>
        <h3>Familie en Vrije Tijd</h3>
        <p>
          Voor veel Nederlanders is Tweede Paasdag een dag om door te brengen met familie en vrienden. Het is een gelegenheid voor gezamenlijke maaltijden, 
          zoals een uitgebreide paasbrunch of -lunch, en voor activiteiten zoals wandelen, fietsen of een dagje uit.
        </p>
        <h3>Activiteiten en Evenementen</h3>
        <p>
          Op Tweede Paasdag worden er door het hele land verschillende evenementen georganiseerd. Dit kunnen paasmarkten, muziekfestivals, tentoonstellingen en sportevenementen zijn. 
          Veel mensen bezoeken dierentuinen, pretparken of gaan naar de kust om te genieten van de vrije dag.
        </p>
        <h3>Paasvuren en Traditionele Gebruiken</h3>
        <p>
          In sommige regio's van Nederland is het gebruikelijk om paasvuren te ontsteken. Deze traditie symboliseert het verjagen van de winter en het verwelkomen van de lente. 
          De vuren worden vaak op Paasmaandag aangestoken en trekken veel bezoekers die samenkomen om het vuur te zien en te genieten van de festiviteiten eromheen.
        </p>
        <h3>Natuur en Buitenactiviteiten</h3>
        <p>
          Tweede Paasdag valt in de lente, waardoor het een ideale tijd is voor buitenactiviteiten. Veel mensen maken gebruik van de vrije dag om te wandelen, 
          te fietsen of gewoon te genieten van de natuur. Nationale parken en natuurgebieden zijn populaire bestemmingen voor gezinnen die de dag buiten willen doorbrengen.
        </p>
        <p>
          Al met al biedt Tweede Paasdag een verlenging van de paasvieringen en een kans om te ontspannen en te genieten van de lente. 
          Of je nu kiest voor een rustige dag thuis, een actieve dag in de natuur of het bijwonen van een lokaal evenement, Tweede Paasdag biedt voor ieder wat wils.
        </p>
      </div>
    </div>
  );
}

export default TweedePaasdag;
