import React from 'react';
import './Regio.css';
import AdSenseAd from '../AdSenseAd';

function Regio() {
  return (
    <div className="region-page">
      <h1>Regio's schoolvakantie</h1>
      <p>Om de schoolvakanties te spreiden heeft Nederland 3 regio’s. Dit zijn de regio’s Noord, Midden en Zuid.</p>
      <p>Hieronder vindt u een overzicht van de regio’s. Per regio ziet u de provincies en gemeenten.</p>
      <AdSenseAd />
      <div className="table-container">
        <section>
          <h2>Regio Noord</h2>
          <table>
            <thead>
              <tr>
                <th>Provincie</th>
                <th>Gemeenten</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Drenthe</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Flevoland</td>
                <td>Alle gemeenten behalve Zeewolde</td>
              </tr>
              <tr>
                <td>Friesland</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Gelderland</td>
                <td>Hattem</td>
              </tr>
              <tr>
                <td>Groningen</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Noord-Holland</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Overijssel</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Utrecht</td>
                <td>Eemnes en wat vroeger gemeente Abcoude was</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <h2>Regio Midden</h2>
          <table>
            <thead>
              <tr>
                <th>Provincie</th>
                <th>Gemeenten</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Flevoland</td>
                <td>Zeewolde</td>
              </tr>
              <tr>
                <td>Gelderland</td>
                <td>Aalten, Apeldoorn, Barneveld, Berkelland, Bronckhorst, Brummen, Buren, Culemborg, Doetinchem, Ede, Elburg, Epe, Ermelo, Harderwijk, Heerde, Lochem, Montferland (behalve wat vroeger gemeente Didam was), Neder-Betuwe (behalve wat vroeger gemeente Dodewaard was), Nijkerk, Nunspeet, Oldebroek, Oost-Gelre, Oude IJsselstreek, Putten, Scherpenzeel, Tiel, Voorst, Wageningen, West Betuwe, Winterswijk en Zutphen</td>
              </tr>
              <tr>
                <td>Noord-Brabant</td>
                <td>Altena (behalve de kernen Hank en Dussen)</td>
              </tr>
              <tr>
                <td>Utrecht</td>
                <td>Alle gemeenten behalve Eemnes en wat vroeger gemeente Abcoude was</td>
              </tr>
              <tr>
                <td>Zuid-Holland</td>
                <td>Alle gemeenten</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <h2>Regio Zuid</h2>
          <table>
            <thead>
              <tr>
                <th>Provincie</th>
                <th>Gemeenten</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gelderland</td>
                <td>Arnhem, Berg en Dal, Beuningen, Doesburg, Druten, Duiven, Heumen, Neder-Betuwe (alleen wat vroeger gemeente Dodewaard was), Lingewaard, Maasdriel, Montferland (alleen wat vroeger gemeente Didam was), Nijmegen, Overbetuwe, Renkum, Rheden, Rozendaal, Rijnwaarden, Westervoort, West Maas en Waal, Wijchen, Zaltbommel en Zevenaar</td>
              </tr>
              <tr>
                <td>Limburg</td>
                <td>Alle gemeenten</td>
              </tr>
              <tr>
                <td>Noord-Brabant</td>
                <td>Alle gemeenten behalve Woudrichem en de kernen Sleeuwijk, Nieuwendijk en Werkendam in de gemeente Altena</td>
              </tr>
              <tr>
                <td>Zeeland</td>
                <td>Alle gemeenten</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <div>
        <p>Kijk voor meer informatie op de site van de rijksoverheid: <a href="https://www.rijksoverheid.nl/onderwerpen/schoolvakanties/regios-schoolvakantie" target="_blank" rel="noopener noreferrer">Regio informatie</a>.</p>
      </div>
    </div>
  );
}

export default Regio;
