import React from 'react';
import './CalendarIntegration.css';

function CalendarIntegration({ holiday }) {
  const addToGoogleCalendar = () => {
    const event = {
      summary: holiday.name,
      start: { date: holiday.from || holiday.date },
      end: { date: holiday.to || holiday.date },
    };
    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.summary)}&dates=${event.start.date}/${event.end.date}`;
    window.open(url, '_blank');
  };

  const addToOutlookCalendar = () => {
    const event = {
      summary: holiday.name,
      start: { date: holiday.from || holiday.date },
      end: { date: holiday.to || holiday.date },
    };
    const url = `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${encodeURIComponent(event.summary)}&startdt=${event.start.date}&enddt=${event.end.date}`;
    window.open(url, '_blank');
  };

  const downloadICS = () => {
    const event = {
      summary: holiday.name,
      start: { date: holiday.from || holiday.date },
      end: { date: holiday.to || holiday.date },
    };
    const icsData = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nSUMMARY:${event.summary}\nDTSTART:${event.start.date.replace(/-/g, '')}\nDTEND:${event.end.date.replace(/-/g, '')}\nEND:VEVENT\nEND:VCALENDAR`;
    const blob = new Blob([icsData], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${event.summary}.ics`;
    link.click();
  };

  return (
    <div className="calendar-buttons">
      <button onClick={addToGoogleCalendar}>Google</button>
      <button onClick={addToOutlookCalendar}>Outlook</button>
      <button onClick={downloadICS}>ICS</button>
    </div>
  );
}

export default CalendarIntegration;
