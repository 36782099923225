import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function InternationaleDagVanHetKind({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Internationale Dag van het Kind | Vrije Dagen NL</title>
  <meta name="description" content="Lees alles over de Internationale Dag van het Kind en de viering van kinderrechten en welzijn wereldwijd." />
  <meta name="keywords" content="Internationale Dag van het Kind, kinderrechten, welzijn, educatie, kindveiligheid" />
  <meta property="og:title" content="Internationale Dag van het Kind" />
  <meta property="og:description" content="Ontdek de betekenis en het belang van de Internationale Dag van het Kind voor kinderrechten en welzijn." />
  <meta property="og:url" content="https://vrije-dagen.nl/internationale%20dag%20van%20het%20kind" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="internationale-dag-van-het-kind-info">
        <h1>Wat is Internationale Dag van het Kind?</h1>
        <p>
          De Internationale Dag van het Kind, gevierd op 1 juni, is een speciale dag gewijd aan de rechten en het welzijn van kinderen over de hele wereld. 
          Het benadrukt het belang van kinderbescherming, educatie en de kansen die elk kind verdient.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Focus op Kinderrechten</h3>
        <p>
          De Internationale Dag van het Kind staat in het teken van kinderrechten, zoals het recht op onderwijs, gezondheidszorg, veiligheid en een liefdevolle omgeving. 
          Het herinnert ons eraan dat elk kind recht heeft op een gezonde en gelukkige kindertijd.
        </p>
        <h3>Oorsprong van Internationale Dag van het Kind</h3>
        <p>
          Deze dag werd voor het eerst gevierd in de 20e eeuw om aandacht te vestigen op de uitdagingen waarmee kinderen worden geconfronteerd. 
          Sindsdien is het een symbool geworden van hoop en actie om het welzijn van kinderen wereldwijd te verbeteren.
        </p>
        <h3>Evenementen en Activiteiten</h3>
        <p>
          Wereldwijd worden er evenementen en activiteiten georganiseerd, zoals sportdagen, culturele programma's en educatieve campagnes, 
          die gericht zijn op het versterken van het bewustzijn rondom kinderrechten en bescherming.
        </p>
        <h3>Hoe kunt u bijdragen?</h3>
        <p>
          U kunt bijdragen aan deze speciale dag door deel te nemen aan lokale activiteiten, doneren aan kinderorganisaties, of simpelweg 
          meer te leren over de kinderrechten en hoe u een verschil kunt maken in het leven van kinderen in uw gemeenschap.
        </p>
        <h3>Een Beter Toekomst voor Elk Kind</h3>
        <p>
          De Internationale Dag van het Kind is een oproep aan iedereen om zich in te zetten voor de toekomst van onze kinderen. 
          Samen kunnen we werken aan een wereld waarin elk kind kan bloeien en zijn volledige potentieel kan bereiken.
        </p>
      </div>
    </div>
  );
}

export default InternationaleDagVanHetKind;