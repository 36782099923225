import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function DagVanDeRuimtevaart({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
      <Helmet>
  <title>Dag van de Ruimtevaart | Vrije Dagen NL</title>
  <meta name="description" content="Leer alles over de Dag van de Ruimtevaart, een dag ter viering van ruimteverkenning en technologische innovaties." />
  <meta name="keywords" content="ruimtevaart, Spoetnik, technologie, ruimteonderzoek, dag van de ruimtevaart" />
  <meta property="og:title" content="Dag van de Ruimtevaart" />
  <meta property="og:description" content="Ontdek hoe we ruimteverkenning en technologische innovaties vieren op de Dag van de Ruimtevaart." />
  <meta property="og:url" content="https://vrije-dagen.nl/dag%20van%20de%20ruimtevaart" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="ruimtevaart-info">
        <h1>Dag van de Ruimtevaart</h1>
        <p>
          De Dag van de Ruimtevaart, gevierd op 4 oktober, is een dag om de ruimteverkenning en de technologische innovaties in de ruimtevaart te vieren. Het is een gelegenheid om stil te staan bij de prestaties die we als mensheid hebben bereikt in het verkennen van het universum.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>Waarom 4 oktober?</h3>
        <p>
          De datum van de Dag van de Ruimtevaart herinnert aan de lancering van de eerste kunstmaan, de Spoetnik 1, op 4 oktober 1957. Dit markeerde het begin van de ruimtevaart en de start van een nieuw tijdperk in de geschiedenis van technologie en verkenning.
        </p>
        <h3>Wat is het doel van deze dag?</h3>
        <p>
          Het doel van de Dag van de Ruimtevaart is om mensen bewust te maken van de vooruitgang in de ruimtevaarttechnologie en de cruciale rol die deze speelt in ons dagelijks leven. Van satellietnavigatie tot ruimteonderzoek, deze innovaties hebben een enorme impact op onze wereld.
        </p>
        <h3>Hoe kun je deze dag vieren?</h3>
        <p>
          Je kunt de Dag van de Ruimtevaart vieren door documentaires te bekijken over ruimteverkenning, deel te nemen aan evenementen in wetenschapsmusea, of een kijkje te nemen in de sterrenhemel met een telescoop. Het is ook een geweldige gelegenheid om meer te leren over de nieuwste ontdekkingen en missies in de ruimte.
        </p>
      </div>
    </div>
  );
}

export default DagVanDeRuimtevaart;