import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpecialeDagenFilter from './SpecialeDagenFilter';
import SpecialeDagenTable from './SpecialeDagenTable';
import AdSenseAd from '../AdSenseAd';
import '../Tables.css';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet

function WereldEmojiDag({ defaultFilters, onFilterChange }) {
  const [specialDays, setSpecialDays] = useState([]);
  const [filteredSpecialDays, setFilteredSpecialDays] = useState([]);
  const [filters, setFilters] = useState(defaultFilters || { year: new Date().getFullYear().toString() });

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/specialedagen.json`, { timeout: 5000 })
      .then(response => {
        const specialDays = response.data.specialDays; // Extract special days array
        setSpecialDays(specialDays);
        filterSpecialDays(specialDays, filters);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (specialDays.length > 0) {
      filterSpecialDays(specialDays, filters);
    }
  }, [filters, specialDays]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const filterSpecialDays = (days, filters) => {
    let updatedDays = days;

    if (!Array.isArray(updatedDays)) {
      console.error('Updated days is not an array:', updatedDays);
      return;
    }

    if (filters.search) {
      updatedDays = updatedDays.filter(day =>
        day.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if (filters.year) {
      updatedDays = updatedDays.filter(day => {
        const dayYear = day.date.split('-')[0];
        return dayYear === filters.year;
      });
    }

    if (filters.month) {
      updatedDays = updatedDays.filter(day =>
        day.date.includes(`-${filters.month}-`)
      );
    }

    setFilteredSpecialDays(updatedDays);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  return (
    <div className="container">
<Helmet>
  <title>Wereld Emoji Dag | Vrije Dagen NL</title>
  <meta name="description" content="Ontdek alles over Wereld Emoji Dag en hoe emoji's de communicatie wereldwijd hebben veranderd." />
  <meta name="keywords" content="Wereld Emoji Dag, emoji, digitale communicatie, feestdag, symbolen" />
  <meta property="og:title" content="Wereld Emoji Dag" />
  <meta property="og:description" content="Vier de emoji-cultuur op Wereld Emoji Dag en ontdek de impact van emoji's op de digitale communicatie." />
  <meta property="og:url" content="https://vrije-dagen.nl/wereld%20emoji%20dag" />
  <meta property="og:type" content="website" />
</Helmet>
      <div className="dag-emoji-info">
        <h1>Wereld Emoji Dag</h1>
        <p>
          Wereld Emoji Dag, gevierd op 17 juli, is een dag ter ere van de emoji-cultuur en hoe deze eenvoudige symbolen de manier waarop mensen wereldwijd communiceren, hebben veranderd. 
          Emojis zijn tegenwoordig een integraal onderdeel van onze digitale communicatie en worden gebruikt om gevoelens, emoties en ideeën op een visuele manier over te brengen.
        </p>
        <SpecialeDagenFilter onFilter={handleFilterChange} defaultFilters={filters} />
        <SpecialeDagenTable holidays={filteredSpecialDays} />
        <AdSenseAd />
        <h3>De Oorsprong van Wereld Emoji Dag</h3>
        <p>
          Wereld Emoji Dag werd voor het eerst gevierd in 2014 en is sindsdien uitgegroeid tot een wereldwijd fenomeen. De datum, 17 juli, werd gekozen omdat deze op de 📅 emoji zelf verschijnt, 
          die vaak wordt weergegeven met deze specifieke datum.
        </p>
        <h3>Hoe Emojis De Wereld Hebben Veranderd</h3>
        <p>
          Emojis hebben de manier waarop we communiceren fundamenteel veranderd door ons de mogelijkheid te geven om complexe emoties en boodschappen op een eenvoudige en speelse manier uit te drukken. 
          Ze zijn meer dan alleen symbolen; ze zijn een universele taal die culturele en taalkundige barrières overstijgt.
        </p>
        <h3>Viering van Wereld Emoji Dag</h3>
        <p>
          Mensen vieren Wereld Emoji Dag op verschillende manieren, waaronder het delen van hun favoriete emojis op sociale media, het gebruik van creatieve emoji-combinaties in berichten, 
          en het deelnemen aan speciale evenementen en activiteiten georganiseerd door bedrijven en online gemeenschappen.
        </p>
      </div>
    </div>
  );
}

export default WereldEmojiDag;